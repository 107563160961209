import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const TravellersStore = {
  state: {
    stepSaved: true,
    groups: [],
    travellerProfile: null,

    basicInformation: null,
    transportData: null,
    dietInformation: null,

    confirmationNumber: null,
    confirmationNumberToken: null,
    tagsConditions: [],
    nationalities: [],
    cities: [],
    states: [],
    countries: [],
    interests: [],
    transportsInformation: [],
    locations: [],
    thanksMessage: false,
    generalTerms: '',
    generalTermsCovid: '',
    displayMessages: [],
    // WebCheckin
    travellersCompanion: [],
    travellerProfileLink: '',
    pdiFile: null,
    passportFile: null,
    waiverFile: null,
    isSigned: false,
    traveller: null,
    reservation: null,
    allReservations:[],
    loadingDocs: false,
    pdiFilePreview: null,
    passportFilePreview: null,
    hasPdiFile: false,
  },
  getters,
  mutations,
  actions
}

export default TravellersStore
