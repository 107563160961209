<template lang="pug">
div
  custom-loading(ref="loading")
  #TravallerForm(v-if="travellerProfile")
    header-steps(
      :title="currentTitle"
      :currentStep="currentStepIndex"
      :currentStepValue="currentStepValue"
      :visibleSteps="visibleSteps"
      :travelerSteps="travelerSteps"
      :changeStep="changeStep"
    )
    .tp-form
      //- div(style="width: 100vw; max-width: 768px;")
      .form-container
        component(
          v-for="step, index in visibleSteps"
          :key="step.value"
          :is="step.component"
          :class="{'hide' : currentStepValue !== step.value}"
          :changeStep="changeStep"
          @previousStep="() => previousStep(index)"
          @nextStep="() => nextStep(index)"
          :includesMapi="reservation?.includesMapi"
          v-bind="step.props"
          :loadingRef="$refs.loading"
        )
</template>

<script>
import HeaderSteps from '../components/Form/HeaderSteps.vue'
import BasicInformation from '../components/Form/BasicInformation.vue'
import Transport from '../components/Form/Transport.vue'
import TravellerRequirements from '../components/Form/TravellerRequirements.vue'
import FitnessConditions from '../components/Form/FitnessConditions.vue'
import TravellerInterests from '../components/Form/TravellerInterests.vue'
import AboutUs from '../components/Form/AboutUs.vue'
import CustomLoading from '../components/Utils/CustomLoading.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  checkProfileIsBlocked,
  checkDestinationsBlocked,
} from '../components/utils'
import MapiForm from '../components/Form/MapiForm.vue'
import { stepValues, stepList } from '@/components/Form/formSteps'
import gql from 'graphql-tag'
import { Loading } from 'element-ui'

export default {
  components: {
    HeaderSteps,
    BasicInformation,
    TravellerRequirements,
    Transport,
    FitnessConditions,
    TravellerInterests,
    AboutUs,
    MapiForm,
    CustomLoading
  },
  data() {
    return {
      currentStepIndex: 0,
    }
  },
  computed: {
    ...mapGetters(['travellerProfile', 'confirmationNumber', 'confirmationNumberToken']),
    stepValues: () => stepValues,

    reservation() {
      return this.travellerProfile?.reservationConsulted
    },

    /**
     * Definición de pasos y sus componentes
     */
    stepDefinitions() {
      return [
        {
          value: stepValues.BASIC_INFO,
          titleKey: 'basicInformation',
          component: BasicInformation,
          props: {
            isBlocked: this.reservationConsultedIsblocked(),
            destinationsBlocked: this.destinationsBlocked(),
          },
        },
        {
          value: stepValues.TRANSPORT_ARRIVAL,
          titleKey: 'transport',
          component: Transport,
          props: {
            type: 'ARRIVAL',
            destinationsBlocked: this.destinationsBlocked(),
          },
        },
        {
          value: stepValues.TRANSPORT_DEPARTURE,
          titleKey: 'transport',
          component: Transport,
          props: {
            type: 'DEPARTURE',
            destinationsBlocked: this.destinationsBlocked(),
          },
        },
        {
          value: stepValues.MAPI_VISIT,
          titleKey: 'mapi',
          component: MapiForm,
          props: {
            isBlocked: this.reservationConsultedIsblocked(),
          },
        },
        {
          value: stepValues.REQUIREMENTS,
          titleKey: 'travellerRequirements',
          component: TravellerRequirements,
          props: {
            isBlocked: this.reservationConsultedIsblocked(),
          },
        },
        // {
        //   value: stepValues.FITNESS_CONDITIONS,
        //   titleKey: 'physicalState',
        //   component: FitnessConditions,
        //   props: {
        //     isBlocked: this.reservationConsultedIsblocked(),
        //   },
        // },
        // {
        //   value: stepValues.INTERESTS,
        //   titleKey: 'interests',
        //   component: TravellerInterests,
        //   props: {
        //     isBlocked: this.reservationConsultedIsblocked(),
        //   },
        // },
        {
          value: stepValues.ABOUT_US,
          titleKey: 'aboutUs',
          component: AboutUs,
          props: {
            isBlocked: this.reservationConsultedIsblocked(),
          },
        },
      ]
    },

    /**
     * Lista de pasos que puede ver el viajero
     *
     * Se ordenan según el orden definido en formSteps.js
     * Se filtran según el filtro personalizado
     * Se filtran los que no tienen información desde el back
     */
    visibleSteps() {
      const reservationSteps = this.reservation.steps || []
      return this.stepDefinitions
        .slice()
        .sort((a, b) => stepList.indexOf(a.value) - stepList.indexOf(b.value))
        .filter((step) => this.customStepFilter(step.value))
        .filter((step) => reservationSteps.find((s) => s.step === step.value))
        .map((step, index) => ({ ...step, position: index }))
    },

    /**
     * Lista de pasos con los objetos del back
     */
    travelerSteps() {
      return this.visibleSteps.map((step) =>
        this.reservation.steps.find((s) => s.step === step.value)
      )
    },

    /**
     * Paso actual (objeto del front)
     */
    currentStep() {
      return this.visibleSteps.find(
        (step) => step.position === this.currentStepIndex
      )
    },

    /**
     * Valor interno del paso actual (definidos en formSteps.js)
     */
    currentStepValue() {
      return this.currentStep?.value
    },

    /**
     * Título del paso actual
     */
    currentTitle() {
      const titleKey = this.currentStep?.titleKey || 'basicInformation'
      return this.$t(`lang.${titleKey}.title`)
    },
  },

  methods: {
    ...mapActions([
      'getFormInformation',
      'getTransportInformation',
      'getProfile',
      'getDiet',
      'getBasicData',
      'getTags',
      'modifyTravellerStepStatus',
      'getInterests',
      'getLocations',
    ]),

    reservationConsultedIsblocked() {
      return checkProfileIsBlocked(this.reservation)
    },

    destinationsBlocked() {
      return checkDestinationsBlocked(
        this.reservation,
        this.travellerProfile.aditionalReservations
      )
    },

    eventChangeStep(step) {
      this.$ga.event(
        'Traveller Profile',
        `Entra al paso ${step}`,
        'Confirmation Number',
        this.confirmationNumber
      )
    },

    /**
     * Filtro adicional para ocultar pasos según reglas específicas
     * @param {*} value Valor del paso a filtrar
     */
    customStepFilter(value) {
      if (value === stepValues.MAPI_VISIT) {
        return this.reservation?.includesMapi
      }
      return true
    },

    async changeStep(step) {
      window.scrollTo(0, 0)
      this.currentStepIndex = step
      this.eventChangeStep(step)

      const travelerStep = this.reservation.steps[this.currentStepValue]
      if (travelerStep?.status === 'NO_OK') {
        this.modifyTravellerStepStatus({
          token: this.$route.params.profileToken,
          travellerId: this.travellerProfile.idTraveller,
          stepNumber: step,
          status: 'IN_PROGRESS',
          confirmationNumber: this.$route.params.confirmationNumber,
          legNumber: 1,
        })
      }
    },

    nextStep(fromStep) {
      console.log('fromStep', fromStep)
      const next = fromStep + 1
      console.log('next', next)
      if (next < this.visibleSteps.length) this.changeStep(next)
    },

    previousStep(fromStep) {
      const prev = fromStep - 1
      if (prev >= 0) return this.changeStep(prev)
      this.$router.push(`/travellerProfile/${this.confirmationNumberToken}`)
    },

    goToFinalStep() {
      const lastStep = this.travelerSteps.findIndex(
        (s) => s.status === 'NO_OK' || s.status === 'IN_PROGRESS'
      )
      if (lastStep !== -1) this.changeStep(lastStep)
    },
    async syncProfile() {
      this.loading = true
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($confirmationNumber: Int!) {
              OperaBasicSynchronizeById(
                confirmationNumber: $confirmationNumber
                legNumber: 1
              ) {
                result
              }
            }
          `,
          client: 'exploraPublic',
          variables: {
            confirmationNumber: this.$route.params.confirmationNumber
          },
        })
        .then(async ({ data }) => {
          this.loading = false
        })
    },
  },

  async created() {
    const loading = Loading.service({ fullscreen: true })
    this.$store.commit('SET_TRAVELLER_PROFILE', { travellerProfile: null })
    // await this.syncProfile()
    this.getFormInformation()
    this.getTags()
    this.getLocations()
    this.getInterests({
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    this.getTransportInformation({
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    await this.getProfile({
      token: this.$route.params.profileToken,
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    // await this.getDiet({
    //   token: this.$route.params.profileToken,
    //   confirmationNumber: this.$route.params.confirmationNumber,
    // })
    loading.close()
    // this.goToFinalStep()
  },
}
</script>

<style lang="scss">
#TravallerForm {
  .tp-form {
    display: flex;
    justify-content: center;
  }
  .hide {
    display: none !important;
  }
  h1,
  h2 {
    font-weight: normal;
  }
  .button-group {
    display: flex;
    justify-content: space-evenly;
    margin: 24px 0;
  }
}
</style>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  max-width: 768px;
}
</style>
