<template lang="pug">
#Avatar
  .avatar-profile
    .icon-relative
      .icon-camera
        .el-icon-camera
    div(style="display: flex;")
      img.avatar-image(
        v-if="loadingAvatar"
        :src="require('../../../public/profile/loading.gif')"
      )
      img.avatar-image.hidden-xs-only(
        v-if="!loadingAvatar"
        :src="traveller.avatarUrl ? traveller.avatarUrl : require('../../../public/profile/profile.png')"
        @click="showCropper = true"
      )
      img.avatar-image.hidden-sm-and-up(
        v-if="!loadingAvatar"
        :src="traveller.avatarUrl ? traveller.avatarUrl : require('../../../public/profile/profile.png')"
        @click="showUploadPicModal = true"
      )

      .step-count
        .div(v-if="formStatus && formStatus !== 'NO_OK'")
          .icon-status-warning(v-if="formStatus && formStatus === 'IN_PROGRESS'")
            .warning-icon !
          .icon-status-check(v-if="formStatus && formStatus === 'OK'")
            .el-icon-check
        label(v-if="formStatus && formStatus !== 'NO_OK'") {{ completedSteps }}/5

    .profile-text
      label {{ traveller.firstName + " " + traveller.lastName }}

    //- TODO: LANGUAGE
    el-dialog.picture-dialog(
      title="Subir foto de perfil"
      :visible.sync="showUploadPicModal"
    )
      .avatar-menu
        el-button.btn-picture(@click="handleTakePicture")
          .el-icon-camera
          div Cámara
        el-button.btn-picture(@click="handleChoosePicture")
          .el-icon-upload
          div Archivos

    avatar-cropper(
      ref="avatarCropper"
      v-model="showCropper"
      :uploadHandler="handleUploadAvatar"
      :capture="captureMode"
    )

  .actions(v-if="loading || (thanksMessage && !stepSaved)")
    p.sync-text(v-if="(thanksMessage && !stepSaved)") Guardando perfil...
    p.sync-text(v-else)
      .el-icon-loading
  .actions(v-else)
    div
      el-tooltip(class="item" effect="dark" :content="$t('lang.welcome.checkinTooltip')" placement="top-start")
        .create-action(style="margin-bottom: 10px;" v-if="isNearCheckin")
          .el-icon-s-order
          label(style="margin-left: 8px; cursor: pointer;", @click="goToCheckinForm(traveller.token, reservation.confirmationNumber)") {{ $t('lang.control.button.completeCheckin') }}
      .create-action(v-if="isBlocked" @click="goToTravellerForm(traveller.token, reservation.confirmationNumber)")
        .el-icon-finished
        label(style="margin-left: 8px; cursor: pointer;") {{ $t('lang.control.button.review') }}

        //- el-tooltip(class="item" effect="dark" :content="$t('lang.blockedComponent.blockedMsg')" placement="top-start")
        //-   .tooltip ?
      .create-action(:id="'action-edit-' + index + '-' + indexGroup" v-else)
        .el-icon-user-solid
        label(style="margin-left: 8px;", @click="goToTravellerForm(traveller.token, reservation.confirmationNumber)")
          | {{ isComplete ? $t('lang.control.button.modify') : $t('lang.control.button.complete') }}
      .create-action(v-if="titular && (index > 0 || indexGroup > 0)")
        .el-icon-s-promotion
        label(style="margin-left: 8px;", @click="open(traveller.email, reservation.confirmationNumber)") {{ $t('lang.control.button.sendEmail') }}
</template>
<script>
import gql from 'graphql-tag'
import { checkProfileIsBlocked, checkIsNearCheckin } from '../utils'
import { mapGetters, mapActions } from 'vuex'
import 'element-ui/lib/theme-chalk/display.css'
import Compress from 'compress.js'

export default {
  props: [
    'traveller',
    'titular',
    'index',
    'indexGroup',
    'thanksMessage',
    'reservation',
  ],
  data() {
    return {
      formStatus: null,
      completedSteps: 0,
      loading: false,
      loadingAvatar: false,
      showCropper: false,
      showUploadPicModal: false,
      captureMode: undefined,
      compressor: null,
    }
  },
  computed: {
    ...mapGetters(['stepSaved']),
    isComplete() {
      return this.completedSteps >= 7
    },
    isBlocked() {
      return checkProfileIsBlocked(this.reservation)
    },
    isNearCheckin() {
      return checkIsNearCheckin(this.reservation)
    },
  },
  methods: {
    ...mapActions(['uploadTravellerAvatar']),

    async handleTakePicture() {
      this.captureMode = 'environment'
      await this.$nextTick()
      this.showCropper = true
      this.showUploadPicModal = false
    },

    async handleChoosePicture() {
      this.captureMode = undefined
      await this.$nextTick()
      this.showCropper = true
      this.showUploadPicModal = false
    },

    async handleUploadAvatar() {
      this.loadingAvatar = true
      const comp = this.$refs.avatarCropper
      comp.cropper.getCroppedCanvas(comp.outputOptions).toBlob(async (originalBlob) => {
        const blob = await this.compressor.compress(originalBlob, {
          quality: 0.9,
          crop: false,
          maxWidth: 500,
          maxHeight: 500,
        })

        const form = new FormData()
        form.append('token', this.traveller.token)
        form.append('file', blob, comp.getFilename(blob))

        this.uploadTravellerAvatar(form).then((res) => {
          if (res) {
            this.loadingAvatar = false
            this.$emit('avatarChanged', res.data.url)
            this.$toasted.show(
              'success',
              // this.$t('lang.travellerProfile.avatar.success'),
              {
                theme: 'done',
                position: 'top-right',
                duration: 5000,
              }
            )
            this.$emit('uploaded')
          } else {
            this.$toasted.show(
              'error',
              // this.$t('lang.travellerProfile.avatar.error'),
              {
                theme: 'bubble',
                position: 'top-right',
                duration: 5000,
              }
            )
          }
        })
      })
    },
    goToCheckinForm(tokenProfile, confirmationNumber) {
      this.$ga.event(
        'Traveller Profile',
        'Entra al checkin de viajero',
        'Confirmation Number',
        confirmationNumber
      )
      this.$router.push(
        `/travellerProfile/checkin/${tokenProfile}/confirmationNumber/${confirmationNumber}`
      )
    },

    async goToTravellerForm(tokenProfile, confirmationNumber) {
      this.$ga.event(
        'Traveller Profile',
        'Entra al formulario de viajero',
        'Confirmation Number',
        confirmationNumber
      )
      await this.syncProfile()
      this.$router.push(
        `/travellerProfile/modifyProfile/${tokenProfile}/confirmationNumber/${confirmationNumber}`
      )
    },

    open(email, confirmationNumber) {
      if (email) {
        this.$confirm(
          this.$t('lang.email.areYouSureText'),
          this.$t('lang.email.emailTitle'),
          {
            confirmButtonText: this.$t('lang.email.send'),
            cancelButtonText: this.$t('lang.email.cancell'),
          }
        )
          .then(() => {
            this.sendByEmail(confirmationNumber, email)
          })
          .catch(() => {
            console.log('Se cancela la acción')
          })
      } else {
        this.$prompt(
          this.$t('lang.email.noEmail'),
          this.$t('lang.email.emailTitle'),
          {
            confirmButtonText: this.$t('lang.email.send'),
            cancelButtonText: this.$t('lang.email.cancell'),
            inputPattern:
              /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            inputErrorMessage: this.$t('lang.email.invalid'),
          }
        )
          .then(({ value }) => {
            this.sendByEmail(confirmationNumber, value)
          })
          .catch(() => {
            console.log('Se cancela la acción')
          })
      }
    },
    async sendByEmail(confirmationNumber, email) {
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($confirmationNumber: ID!, $email: String!) {
              SendTravellerProfileLink(
                confirmationNumber: $confirmationNumber
                email: $email
              ) {
                result
                message
              }
            }
          `,
          client: 'exploraPublic',
          variables: {
            confirmationNumber: confirmationNumber,
            email: email,
          },
        })
        .then(async ({ data }) => {
          if (data.SendTravellerProfileLink.result) {
            this.$toasted.show(data.SendTravellerProfileLink.message, {
              theme: 'done',
              position: 'top-right',
              duration: 5000,
            })
          } else {
            this.$toasted.show(data.SendTravellerProfileLink.message, {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000,
            })
          }
        })
    },
    getFormStatus() {
      let noOkCount = 0
      let inProgressCount = 0
      let OkCount = 0

      this.reservation.steps
        .filter((s) => s.legNumber === 1 || s.legNumber === null)
        .forEach((s) => {
          if (s.status === 'NO_OK') noOkCount += 1
          if (s.status === 'IN_PROGRESS') inProgressCount += 1
          if (s.status === 'OK') OkCount += 1
        })

      this.completedSteps = OkCount

      if (noOkCount === 7) {
        this.formStatus = 'NO_OK'
      }
      if (inProgressCount > 0) {
        this.formStatus = 'IN_PROGRESS'
      }
      if (OkCount === 7) {
        this.formStatus = 'OK'
      }
    },
    async syncProfile() {
      this.loading = true
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($confirmationNumber: Int!) {
              OperaBasicSynchronizeById(
                confirmationNumber: $confirmationNumber
                legNumber: 1
              ) {
                result
              }
            }
          `,
          client: 'exploraPublic',
          variables: {
            confirmationNumber: this.reservation.confirmationNumber,
            legNumber: this.reservation.legNumber,
          },
        })
        .then(async ({ data }) => {
          this.loading = false
        })
    },
  },
  created() {
    this.compressor = new Compress()
    this.getFormStatus()
  },
}
</script>

<style lang="scss">
@import './AvatarStyle.scss';

.cropper-crop-box {
  .cropper-view-box,
  .cropper-face.cropper-move {
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
.avatar-menu {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.picture-dialog::v-deep .el-dialog {
  width: fit-content;
}

.picture-dialog::v-deep .el-dialog__body {
  padding: 10px;
}

.btn-picture {
  width: 40vw;
  height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::v-deep span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &::v-deep .el-icon-camera,
  &::v-deep .el-icon-upload {
    font-size: 80px;
  }
}
.el-icon-loading {
  color: #f99921;
  font-size: 26px;
  margin-right: 16px;
}
</style>
