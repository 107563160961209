<template lang="pug">
div
  h3 {{ $t('lang.checkin.showWaiverCheckin') }}
  .waiver-box(v-if="waiverFile")
    PDFReader(:src="waiverFile")
  .button-group(style="margin-top: 16px;")
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
</template>

<script>
import vueSignature from "vue-signature";
import PDFReader from "./Tools/PDFReader.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    vueSignature,
    PDFReader
  },
  computed: {
    ...mapGetters(['waiverFile']),
  },
  methods: {
    goBack () {
      this.$emit('changeStep', 1)
    },
  },
};
</script>
<style lang="scss" scoped>
#Waiver {
  border: 2px rgb(0, 0, 0) solid;
  border-radius: 5px;
  width: 400px;
  height: 130px;
  margin-left: 10px;
}
.waiver-box {
  height: 630px;
  overflow-y: auto;
  border: 1px rgb(0, 0, 0) solid;
}
</style>