<template lang="pug">
#ReservationsAttachment
  div(v-for="(reservation, index) in auxReservations")
    #Avatar(v-if="reservation.hotel.needsMobilityPass && (reservation.travellerObj.birthday > 17 || reservation.travellerObj.birthday === null)")
      .avatar-profile
        img.avata-image(src="../../public/profile/profile.png")
        .profile-text
          label.name {{ reservation.travellerObj.firstName + " " + reservation.travellerObj.lastName }}
      .actions
        .blocked-msg(v-if="isBlocked(reservation)")
          label Bloqueado
          el-tooltip(class="item" effect="dark" content="La reserva puede estar cancelada o checked-in" placement="top-start")
            .tooltip ?
        div(v-else)
          div(:style="reservation.mobilityPass ? 'margin-bottom: 10px;' : ''")
            label(for="file-input")
              .create-action(@click="setActiveTraveller(reservation.travellerObj,'PASE_MOVILIDAD'), setActiveReservation(reservation)")
                .el-icon-upload2
                label(for="file-input", style="margin-left: 8px; cursor: pointer;") {{ $t('lang.attachments.upload') }}
            input.no-show(type="file", id="file-input", @change="onFilePicked", accept="application/pdf")
          a(v-if="reservation.mobilityPass", :href="reservation.mobilityPass", target="_blank")
            .create-action
              .el-icon-s-order
              label(style="margin-left: 8px; cursor: pointer;") {{ $t('lang.attachments.ver') }}
    .message(v-if="reservation.hotel.code === 'EATA'") {{ $t('lang.attachments.messageATA') }}
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { checkAllIsBlocked } from './utils'

export default {
  props: ['reservations'],
  data () {
    return {
      activeName: '',
      activeTraveller: null,
      activeFileType: '',
      auxReservations: [],
      activereservation: null
    }
  },
  created () {
    this.auxReservations = this.reservations
    this.auxReservations.forEach(r => {
      if (r.travellerObj.birthday != null) {
        r.travellerObj.birthday = moment().diff(r.travellerObj.birthday, 'years')
      }
    })
  },
  methods: {
    ...mapActions(['AddOrUpdateAttachment']),
    async onFilePicked (event) {
      const files = event.target.files
      const fileReader = new FileReader()
      const file = files[0]
      try {
        if (file.name.split('.')[1] !== 'pdf' && file.name.split('.')[1] !== 'PDF') {
          this.$notify({
            title: this.$t('lang.attachments.problem'),
            message: this.$t('lang.attachments.pdfError'),
            type: 'error'
          })
        } else {
          this.$notify({
            title: this.$t('lang.attachments.uploading'),
            message: this.$t('lang.attachments.upDescription'),
            type: 'info'
          })
          fileReader.readAsDataURL(file)
          const dataTicket = new FormData()
          dataTicket.append('reservation', this.activereservation.id)
          dataTicket.append('file_type', this.activeFileType)
          dataTicket.append('attachment', file)
          const resp = await this.AddOrUpdateAttachment(dataTicket)
          if (this.activeFileType === 'CONAF') this.auxReservations.find(r => r.id === this.activereservation.id).conafFile = resp.data
          if (this.activeFileType === 'PASE_MOVILIDAD') this.auxReservations.find(r => r.id === this.activereservation.id).mobilityPass = resp.data
          this.$notify({
            title: this.$t('lang.attachments.ready'),
            message: this.$t('lang.attachments.uploaded'),
            type: 'success'
          })
        }
      } catch {
        this.$notify({
          title: this.$t('lang.attachments.problem'),
          message: this.$t('lang.attachments.generalError'),
          type: 'error'
        })
      }
    },
    isBlocked (reservation) {
      return checkAllIsBlocked(reservation, true)
    },
    setActiveTraveller (traveller, fileType) {
      this.activeTraveller = traveller
      this.activeFileType = fileType
    },
    setActiveReservation (reservation) {
      this.activereservation = reservation
    }
  }
}
</script>
<style lang="scss">
@import './TravellerProfile/AvatarStyle.scss';
#ReservationsAttachment {
  text-align: left;
  .el-icon-upload2, .el-icon-s-order{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  label {
    height: 17px;
  }
  .no-show {
    display: none;
  }
}
.el-tabs__item.is-active, .el-tabs__item:hover {
  color: #303133 !important;
  font-weight: bold;
}
.el-tabs__active-bar {
  background-color: #303133 !important;
}
.el-tabs__nav-wrap::after {
  background-color: transparent !important;
}
.message {
  font-size: 11px;
  color: gray;
}
</style>
