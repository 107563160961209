<template lang="pug">
#TravellerCheckinForm
  Header
  #Header
    h3(v-show="step != 5 && step != 6") {{ $t('lang.checkin.completeCheckin') }}
  .container
    Documents(v-show="step == 1" @changeStep="changeStep", :canContinue="canContinue", :hotelCountries="hotelCountries")
    RegisterYoung(v-show="step == 2" @changeStep="changeStep", @generateLink="generateLink")
    Waiver(v-if="step == 3" @changeStep="changeStep", :htmlWaiver="htmlWaiver", :urlWaiver="urlWaiver" :step="step")
    DocumentsCountry(v-show="step == 4" @changeStep="changeStep", :canContinue="canContinue", :hotelCountries="hotelCountries")
  CheckinFinished(v-if="step == 5", @changeStep="changeStep")
  WaiverView(v-if="step == 6" @changeStep="changeStep")
</template>
<script>
import Header from '@/components/Header.vue'
import Documents from '@/components/Checkin/Documents.vue'
import DocumentsCountry from '../components/Checkin/DocumentsCountry.vue'
import FileUpload from '@/components/FileUpload.vue'
import Waiver from '@/components/Checkin/Waiver.vue'
import RegisterYoung from '@/components/Checkin/RegisterYoung.vue'
import CheckinFinished from '@/components/Checkin/CheckinFinished.vue'
import WaiverView from '@/components/Checkin/WaiverView.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Header,
    FileUpload,
    Waiver,
    Documents,
    RegisterYoung,
    CheckinFinished,
    WaiverView,
    DocumentsCountry,
  },
  data () {
    return {
      step: 1,
      htmlWaiver: '',
      urlWaiver: '',
      canContinue: false,
    }
  },
  computed: {
    ...mapGetters(['isSigned', 'traveller', 'reservation', 'allReservations', 'isSigned', 'pdiFile']),
    hotelCountries() {
      const countries = []
      this.allReservations.forEach(reservation => {
        if (reservation.hotel.code === 'ECHA') {
          countries.push('ARG')
        } 
        if (reservation.hotel.code === 'EVAL') {
          countries.push('PER')
        }
        if (!['EVAL', 'ECHA'].includes(reservation.hotel.code)) {
          countries.push('CHIL')
        }
      })

      return countries
    }
  },
  methods: {
    ...mapActions(['signDocument', 'getTravellersTeenagers', 'getProfileLink', 'setReservationAttributes']),
    changeStep(step) {
      this.step = step
    },
    async generateLink(travellersYoung=[]) {
      if (!this.isSigned && this.traveller.age >= 18) {
        let data = new FormData()
        data.append('attachment', '')
        this.allReservations.forEach((reservation, index) => {
          data.append(`reservation_id[${index}]`, reservation.id)
        })
        data.append('token', this.$route.params.profileToken)
        data.append('travellers_young', JSON.stringify(travellersYoung))
        data.append('lang', localStorage.getItem('lang'))
        const { url, waiverHtml } = await this.signDocument({ data })
        this.urlWaiver = url
        this.htmlWaiver = waiverHtml
      }
    },
  },
  async created() {
    await this.getProfileLink({ confirmationNumber: this.$route.params.confirmationNumber, token: this.$route.params.profileToken })
    await this.getTravellersTeenagers({ confirmationNumber: this.$route.params.confirmationNumber, token: this.$route.params.profileToken })
    await this.generateLink()
    if (this.isSigned && this.pdiFile === null) {
      this.step = 4
    }
    this.canContinue = true
  }
}
</script>
<style lang="scss">
#TravellerCheckinForm {
  #Header {
    height: 80px;
  }
  width: 100%;
  .upload-label {
    width: 100%;
    text-align: left;
    padding-left: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 24px;
  }
  @media (min-width: 768px) {
    .container {
      padding: 0 20%;
    }
  }
  .border {
    border-radius: 5px;
    border: black 0.5px solid;
    padding: 8px;
  }
}
</style>
