<template lang="pug">
#FitnessConditions
  //- h2 {{ $t('lang.physicalState.fitnessDescription') }}
  h2 {{ $t('lang.physicalState.physicalState') }}
  .slider
    .sub-slider
      el-slider(v-model="travellerProfile.physicalState", :disabled="isBlocked" :step="1", :show-stop="true", :max="3", :show-tooltip="false", id="sliderPhysicalState")
  div(style="display: flex; justify-content: space-between; text-align: center;")
    .text-legend(v-for="l in physicalLegend", :style="getStyleLegend(l)")
      label {{ l }}
  h2 {{ $t('lang.physicalState.fitnessIntensity') }}
  .slider
    .sub-slider
      el-slider(v-model="travellerProfile.fitnessIntensity", :disabled="isBlocked" :step="1", :format-tooltip="formatTooltipFirstSlider", :show-tooltip="false", :show-stop="true", :max="3", id="sliderFitnessIntensityState")
  div(style="display: flex; justify-content: space-between; text-align: center;")
    .text-legend(v-for="l in freqLengend", :style="getStyleLegend(l)")
      label {{ l }}
  br
  blocked-msg(v-if="isBlocked")
  .button-group(v-else)
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="saveDataConditions", class="saveFitnessBtn") {{ $t('lang.control.button.end') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BlockedMsg from './BlockedMsg.vue'
import { stepValues } from './formSteps'

export default {
  props: ['changeStep', 'isBlocked', 'includesMapi', 'loadingRef'],
  components: {
    BlockedMsg
  },
  data () {
    return {
      physicalLegend: this.$t('lang.physicalState.physicalLegend'),
      freqLengend: this.$t('lang.physicalState.freqLengend')
    }
  },
  computed: {
    ...mapGetters(['travellerProfile', 'confirmationNumber', 'confirmationNumberToken'])
  },
  methods: {
    ...mapActions(['UpdatePhysicalConditions', 'modifyTravellerStepStatus']),
    async saveDataConditions () {
      this.loadingRef.showLoading();
      // this.$emit('nextStep')
      await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.travellerProfile.idTraveller, stepNumber: stepValues.FITNESS_CONDITIONS, status: 'OK', confirmationNumber: this.$route.params.confirmationNumber })
      const resp = await this.UpdatePhysicalConditions({
        travellerId: this.travellerProfile.idTraveller,
        confirmationNumber: this.$route.params.confirmationNumber,
        fitnessIntensity: this.travellerProfile.fitnessIntensity,
        physicalState: this.travellerProfile.physicalState
      })
      this.loadingRef.hideLoading();
      if (resp === true) {
        this.$notify({
          title: this.$t('lang.saved.title'),
          type: 'success',
          customClass: 'step-fitness-complete'
        })
      } else {
        this.$notify({
          title: this.$t('lang.errorSave.title'),
          message: this.$t('lang.errorSave.message'),
          type: 'error'
        })
      }
      this.$router.push(`/travellerProfile/${this.confirmationNumberToken}`)
    },
    getStyleLegend (legend) {
      if (legend === 'Medio') {
        return { 'margin-right': '8px' }
      }
    },
    goBack () {
      this.$emit('previousStep')
    },
    formatTooltipFirstSlider (val) {
      switch (val) {
        case 0:
          return this.$t('lang.physicalState.physicalTooltip1')
        case 1:
          return this.$t('lang.physicalState.physicalTooltip2')
        case 2:
          return this.$t('lang.physicalState.physicalTooltip3')
        case 3:
          return this.$t('lang.physicalState.physicalTooltip4')
      }
    }
  }
}
</script>
<style lang="scss">
#FitnessConditions {
  padding: 8px 16px;
  text-align: left;
  .text-legend {
    font-size: 12px;
    width: 40px;
  }
  .slider {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .sub-slider {
    width: 90%;
  }
}
</style>
