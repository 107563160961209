<template lang="pug">
#TravellerRequirements(v-if="dietInformation")
  //- h1 {{ $t('lang.travellerRequirements.reqDescription') }}
  h2 {{ $t('lang.travellerRequirements.foodReqTitle') }}
  .label-form {{ $t('lang.travellerRequirements.choseOptions') }}
  .input-form
    el-checkbox(v-model="dontNeedFoodReq" id="doNotNeedFoodField" :disabled="isBlocked") {{ $t('lang.travellerRequirements.dontNeedFoodReq') }}
    el-checkbox.req(v-for="fr in dietInformation.foodRequirements" :label="fr.name", :disabled="dontNeedFoodReq || isBlocked" v-bind:key="fr.id", v-model="fr.haveRelation" :id="'food-req-' + fr.id")
  span(v-if="dietInformation.foodRequirements.filter(fr =>  fr.code === 'OTHER' && fr.haveRelation).length > 0")
    .input-form {{ $t('lang.travellerRequirements.otherFoodReq') }}
    el-input(type="textarea", :disabled="isBlocked" :autosize="{ minRows: 2}", v-model="dietInformation.foodRestrictions")
  h2 {{ $t('lang.travellerRequirements.Allergies') }}
  .label-form {{ $t('lang.travellerRequirements.foodAllergies') }}
  vue-tags-input(
    v-model='tagFoodAllergies'
    :tags='foodAllergiesValue'
    @tags-changed='newTags => foodAllergiesValue = newTags'
    :disabled="dontNeedFoodReq || isBlocked"
    placeholder=""
    id="alergyField"
  )
  .label-form {{ $t('lang.travellerRequirements.OtherAllergies') }}
  vue-tags-input(
    v-model='tagMedAllergies'
    :tags='medicalAllergiesValue'
    @tags-changed='newTags => medicalAllergiesValue = newTags'
    :disabled="dontNeedMedReq || isBlocked"
    placeholder=""
  )
  h2 {{ $t('lang.travellerRequirements.mediacalReqTitle') }}
  .label-form {{ $t('lang.travellerRequirements.choseOptionsMedical') }}
  .input-form
    el-checkbox(v-model="dontNeedMedReq" id="doNotNeedMedField" :disabled="isBlocked") {{ $t('lang.travellerRequirements.dontNeedMedicalReq') }}
    el-checkbox.req(v-for="mc in dietInformation.medicalConditions.filter((mc) => mc.code === 'PRGN')", :disabled="dontNeedMedReq || isBlocked", :label="mc.name" v-bind:key="mc.id", v-model="mc.haveRelation")
  span(v-if="dietInformation.medicalConditions.filter(mc =>  mc.code === 'OTHER' && mc.haveRelation).length > 0")
    .input-form {{ $t('lang.travellerRequirements.otherMedReq') }}
    el-input(type="textarea", :autosize="{ minRows: 2}", v-model="dietInformation.otherMedicalConditions" :disabled="isBlocked")
  .label-form {{ $t('lang.travellerRequirements.Diseases') }}
  vue-tags-input(
    v-model='tagMedDiseases'
    :tags='medicalDiseasesValue'
    @tags-changed='newTags => medicalDiseasesValue = newTags'
    :disabled="dontNeedMedReq || isBlocked"
    placeholder=""
  )
  .label-form {{ $t('lang.travellerRequirements.Injuries') }}
  vue-tags-input(
    v-model='tagMedInjuries'
    :tags='medicalInjuriesValue'
    @tags-changed='newTags => medicalInjuriesValue = newTags'
    :disabled="dontNeedMedReq || isBlocked"
    placeholder=""
  )
  .label-form {{ $t('lang.travellerRequirements.Surgies') }}
  vue-tags-input(
    v-model='tagMedSurgies'
    :tags='medicalSurgiesValue'
    @tags-changed='newTags => medicalSurgiesValue = newTags'
    :disabled="dontNeedMedReq || isBlocked"
    placeholder=""
  )
  blocked-msg(v-if="isBlocked")
  .button-group(v-else)
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="saveData", class="saveReqBtn") {{ $t('lang.control.button.continue') }}
.loading-box(v-else)
  div(v-loading="true")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BlockedMsg from './BlockedMsg.vue'
import VueTagsInput from '@johmun/vue-tags-input';
import { stepValues } from './formSteps';

export default {
  props: ['changeStep', 'isBlocked', 'includesMapi', 'loadingRef'],
  components: {
    BlockedMsg,
    VueTagsInput
  },
  data () {
    return {
      tagFoodAllergies: '',
      foodAllergiesValue: [],

      tagMedAllergies: '',
      medicalAllergiesValue: [],

      tagMedDiseases: '',
      medicalDiseasesValue: [],

      tagMedInjuries: '',
      medicalInjuriesValue: [],

      tagMedSurgies: '',
      medicalSurgiesValue: [],

      dontNeedFoodReq: false,
      dontNeedMedReq: false
    }
  },
  async created () {
    await this.getDiet({
      token: this.$route.params.profileToken,
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    this.foodAllergiesValue = this.dietInformation.tags.filter(tag => tag.category === 'FOOD' && tag.subCategory === 'ALLERGY').map(tag => tag.value)
    this.medicalAllergiesValue = this.dietInformation.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'MED_ALLERGY').map(({ value }) => ({ text: value }));
    this.medicalDiseasesValue = this.dietInformation.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'DISEASE').map(({ value }) => ({ text: value }));
    this.medicalInjuriesValue = this.dietInformation.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'INJURY').map(({ value }) => ({ text: value }));
    this.medicalSurgiesValue = this.dietInformation.tags.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'SURGERY').map(({ value }) => ({ text: value }));
    if (this.dietInformation.hasFoodReq === false) this.dontNeedFoodReq = true
    if (this.dietInformation.hasMedicalReq === false) this.dontNeedMedReq = true
  },
  methods: {
    ...mapActions(['saveTravellerRequirements', 'modifyTravellerStepStatus', 'getDiet']),
    async saveData () {
      if ((this.dontNeedFoodReq === false && this.dietInformation.foodRequirements.filter(fr => fr.haveRelation).length === 0 &&
      this.foodAllergiesValue.length === 0)) {
        this.$notify({
          title: this.$t('lang.errorFood.title'),
          message: this.$t('lang.errorFood.message'),
          type: 'error',
          customClass: 'step-req-error-food'
        })
      } else if ((this.dontNeedMedReq === false && this.dietInformation.medicalConditions.filter(mc => mc.haveRelation).length === 0 &&
      this.medicalDiseasesValue.length === 0 && this.medicalInjuriesValue.length === 0 && this.medicalSurgiesValue.length === 0 && this.medicalAllergiesValue.length === 0)) {
        this.$notify({
          title: this.$t('lang.errorMed.title'),
          message: this.$t('lang.errorMed.message'),
          type: 'error',
          customClass: 'step-req-error-medical'
        })
      } else {
        this.loadingRef.showLoading();
        this.$emit('nextStep')
        let foodAllergies = this.foodAllergiesValue.map(fa => ({ value: fa.text }))
        let foodRequirements = this.dietInformation.foodRequirements.map(fr => ({ id: fr.id, name: fr.name, haveRelation: fr.haveRelation }))

        if (this.dontNeedFoodReq) {
          foodAllergies = []
          foodRequirements = this.dietInformation.foodRequirements.map(fr => ({ id: fr.id, name: fr.name, haveRelation: false }))
        }

        let medicalDiseases = this.medicalDiseasesValue.map(md => ({ value: md.text }))
        let medicalInjuries = this.medicalInjuriesValue.map(mi => ({ value: mi.text }))
        let medicalSurgies = this.medicalSurgiesValue.map(ms => ({ value: ms.text }))
        let medicalAllergies = this.medicalAllergiesValue.map(ma => ({ value: ma.text }))
        let medicalConditions = this.dietInformation.medicalConditions.map(ma => ({ id: ma.id, name: ma.name, haveRelation: ma.haveRelation }))

        if (this.dontNeedMedReq) {
          medicalDiseases = []
          medicalInjuries = []
          medicalSurgies = []
          medicalAllergies = []
          medicalConditions = this.dietInformation.medicalConditions.map(ma => ({ id: ma.id, name: ma.name, haveRelation: false }))
        }
        console.log('this.dietInformation', this.dietInformation.idTraveller)
        await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.dietInformation.idTraveller, stepNumber: stepValues.REQUIREMENTS, status: 'OK', confirmationNumber: this.$route.params.confirmationNumber })
        const resp = await this.saveTravellerRequirements({
          travellerId: this.dietInformation.idTraveller,
          hasFoodReq: !this.dontNeedFoodReq,
          hasMedicalReq: !this.dontNeedMedReq,
          foodRequirements: foodRequirements,
          medicalConditions: medicalConditions,
          foodAllergiesTags: foodAllergies,
          medicalDiseaseTags: medicalDiseases,
          medicalInjuryTags: medicalInjuries,
          medicalSurgeryTags: medicalSurgies,
          medicalAllergiesTags: medicalAllergies,
          confirmationNumber: this.dietInformation.reservationConsulted.confirmationNumber,
          foodRestrictions: this.dietInformation.foodRestrictions || '',
          otherMedicalConditions: this.dietInformation.otherMedicalConditions || ''
        })
        this.loadingRef.hideLoading();
        if (resp === true) {
          this.$notify({
            title: this.$t('lang.saved.title'),
            type: 'success',
            customClass: 'step-req-complete'
          })
        } else {
          this.$notify({
            title: this.$t('lang.errorSave.title'),
            message: this.$t('lang.errorSave.message'),
            type: 'error'
          })
        }
      }
    },
    goBack () {
      this.$emit('previousStep')
    }
  },
  watch: {
    dontNeedFoodReq () {
      this.dietInformation.foodRequirements.forEach(el => {
        el.haveRelation = false
      })
      this.dietInformation.foodRestrictions = ''
    },
    dontNeedMedReq () {
      this.dietInformation.medicalConditions.forEach(el => {
        el.haveRelation = false
      })
      this.dietInformation.otherMedicalConditions = ''
    }
  },
  computed: {
    ...mapGetters(['dietInformation', 'tagsConditions', 'confirmationNumber']),
    foodAlergyTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'FOOD' && tag.subCategory === 'ALLERGY')
      } else {
        return []
      }
    },
    medicalAlergyTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'MED_ALLERGY')
      } else {
        return []
      }
    },
    medicalDiseasesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'DISEASE')
      } else {
        return []
      }
    },
    medicalInjuriesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'INJURY')
      } else {
        return []
      }
    },
    medicalSurgiesTags () {
      if (this.tagsConditions) {
        return this.tagsConditions.filter(tag => tag.category === 'MEDICAL' && tag.subCategory === 'SURGERY')
      } else {
        return []
      }
    }
  }
}
</script>
<style lang="scss">
#TravellerRequirements {
  padding: 8px 16px;
  text-align: left;
  .input-form {
    margin-top: 10px;
    display: inline-grid;
  }
  .label-form {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .el-select {
    width: 100%;
    margin-bottom: 24px;
  }
  .req {
    width: 40%;
    margin-bottom: 7px;
  }
  .el-checkbox {
    margin-bottom: 6px;
  }
  .ti-tags {
    border-radius: 4px;
  }
  .ti-disabled {
    border-radius: 4px;
    background: #f2f2f2;
  }
  .vue-tags-input {
    max-width: none;
  }
}
</style>
