<template lang="pug">
#AboutUs(v-else)
  h1 {{ $t('lang.aboutUs.description') }}
  .option(v-for="(option, index) in $t('lang.aboutUs.options')")
    el-radio(v-model="radio" :label="option" :id="'about-' + index" :disabled="isBlocked")
  .option
    el-radio(v-model="radio" :label="$t('lang.aboutUs.other')" id="about-otro" :disabled="isBlocked")
  el-input(v-model="other", maxlength="100", v-if="radio === $t('lang.aboutUs.other')" :disabled="isBlocked", id="other-about-us")
  h1 {{ $t('lang.aboutUsFactor.description') }}
  .option(v-for="(option, index) in $t('lang.aboutUsFactor.options')")
    el-radio(v-model="radioFactor" :label="option" :id="'desc-' + index" :disabled="isBlocked")
  .option
    el-radio(v-model="radioFactor" :label="$t('lang.aboutUsFactor.other')" id="desc-otro" :disabled="isBlocked")
  el-input(v-model="otherFactor", :disabled="isBlocked" maxlength="100", v-if="radioFactor === $t('lang.aboutUsFactor.other')", id="other-about-us")
  blocked-msg(v-if="isBlocked")
  .button-group(v-else)
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="saveData" class="saveAboutUs") {{ $t('lang.control.button.end') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { Loading } from 'element-ui'
import BlockedMsg from './BlockedMsg.vue'
import { stepValues } from './formSteps'

export default {
  props: ['changeStep', 'isBlocked'],
  components: {
    BlockedMsg
  },
  data () {
    return {
      radio: '',
      other: '',
      radioFactor: '',
      otherFactor: '',
    }
  },
  computed: {
    ...mapGetters(['travellerProfile', 'confirmationNumberToken'])
  },
  methods: {
    ...mapActions(['updateTravellerAboutUsAnswer', 'modifyTravellerStepStatus']),
    async saveData () {
      const loading = Loading.service()
      const answer = this.radio === this.$t('lang.aboutUs.other') ? this.other : this.radio
      const answerFactor = this.radioFactor === this.$t('lang.aboutUs.other') ? this.otherFactor : this.radioFactor
      this.$store.commit('SET_THANKS_MESSAGE_TRUE')

      await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.travellerProfile.idTraveller, stepNumber: stepValues.ABOUT_US, status: 'OK', confirmationNumber: this.$route.params.confirmationNumber })
      await this.updateTravellerAboutUsAnswer({
        travellerId: this.travellerProfile.idTraveller,
        confirmationNumber: this.$route.params.confirmationNumber,
        aboutUsValue: answer,
        importantFactorValue: answerFactor,
      })

      this.$router.push(`/travellerProfile/${this.confirmationNumberToken}`)
      loading.close()
    },
    goBack () {
      this.$emit('previousStep')
    }
  },
  created () {
    if (this.$t('lang.aboutUs.options').includes(this.travellerProfile.aboutUsAnswer) || this.travellerProfile.aboutUsAnswer === ''
    ) {
      this.radio = this.travellerProfile.aboutUsAnswer
    } else {
      this.radio = this.$t('lang.aboutUs.other')
      this.other = this.travellerProfile.aboutUsAnswer
    }
    if (this.$t('lang.aboutUsFactor.options').includes(this.travellerProfile.aboutUsImportantFactorAnswer) || this.travellerProfile.aboutUsImportantFactorAnswer === ''
    ) {
      this.radioFactor = this.travellerProfile.aboutUsImportantFactorAnswer
    } else {
      this.radioFactor = this.$t('lang.aboutUsFactor.other')
      this.otherFactor = this.travellerProfile.aboutUsImportantFactorAnswer
    }
  }
}
</script>
<style lang="scss">
#AboutUs {
  padding: 8px 16px;
  text-align: left;
  .check-group {
    display: inline;
  }
  .option {
    margin-bottom: 16px;
  }
  .el-select {
    width: 100%;
    margin: 24px 0;
  }
}
</style>
