import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {
  createApolloClient,
  restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client'
import settings from '../settings.js'
import { onError } from 'apollo-link-error'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { createHttpLink } from 'apollo-link-http'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'explora-token'

let link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError) {
    console.log(networkError)
  }
})

const timeoutLink = new ApolloLinkTimeout(1000000)

if (settings.nodeEnv === 'local') {
  const httpLink = createHttpLink({ uri: settings.graphqlHttp })
  link = timeoutLink.concat(httpLink)
}

// Config
const defaultOptionsPrivate = {
  persisting: false,
  tokenName: AUTH_TOKEN,
  websocketsOnly: false,
  ssr: false,
  link: link,
  httpLinkOptions: {
    uri: settings.graphqlHttp,
    credentials: 'include'
  }
}
if (settings.nodeEnv === 'local') {
  const httpLink = createHttpLink({ uri: settings.graphqlHttpPublic })
  link = timeoutLink.concat(httpLink)
}

const defaultOptionsPublic = {
  persisting: false,
  tokenName: AUTH_TOKEN,
  websocketsOnly: false,
  ssr: false,
  link: link,
  httpLinkOptions: {
    uri: settings.graphqlHttpPublic,
    credentials: 'include'
  }
}
// Call this in the Vue app file
export function createProvider (options = {}) {
  const createClientPublic = createApolloClient({
    ...defaultOptionsPublic,
    ...options
  })
  const createClientPrivate = createApolloClient({
    ...defaultOptionsPrivate,
    ...options
  })
  const exploraPublic = createClientPublic.apolloClient
  const exploraPrivate = createClientPrivate.apolloClient
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      exploraPublic,
      exploraPrivate
    },
    defaultClient: exploraPrivate,
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log(error.networkError)
    }
  })
  return apolloProvider
}
export const { apolloClient, wsClient } = createApolloClient({ ...defaultOptionsPublic })
apolloClient.wsClient = wsClient
// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
