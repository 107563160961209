<template lang="pug">
#AirportForm
  h3(v-if="type === 'ARRIVAL'") {{ $t('lang.transport.airportForm.descriptionArrival') }}
  h3(v-if="type === 'DEPARTURE'") {{ $t('lang.transport.airportForm.descriptionDeparture') }}
  el-form(ref="form", :model="form", :rules="rules")
    .input-label {{ $t('lang.transport.airportForm.airport') }}
    el-form-item(prop="airPort")
      el-select(v-model="form.airPort", :disabled="disabled" filterable, :placeholder="$t('lang.transport.airportForm.selectAirPort')", style="width: 100%;", :id="'airportField-' + type")
        el-option(v-for="(a, index) in airPorts", v-bind:key="index", :label="a.value" :value="a.cityCode" :id="'airport-' + a.cityCode + '-' + type")
      .message(style="padding: 0;") {{ airPortWarning }}
    .input-label {{ $t('lang.transport.airportForm.airLine') }}
    el-form-item(prop="airLine")
      el-select(v-model="form.airLine" placeholder="Select" :id="'airLine-' + type" :disabled="disabled")
        el-option(v-for="item in airlines", :key="item.code", :label="item.name", :value="item.code", :id="'airline-' + item.code + '-' + type")
    .input-label {{ $t('lang.transport.airportForm.flightNumber') }}
    el-form-item(prop="flightNumber")
      el-input(v-model="form.flightNumber", type="number", :id="'flightNumberField-' + type" :disabled="disabled")
    .input-label(v-if="type === 'ARRIVAL'") {{ $t('lang.transport.airportForm.arrival') }}
    .input-label(v-if="type === 'DEPARTURE'") {{ $t('lang.transport.airportForm.departure') }}
    .date-time-container
      el-form-item(prop="date", style="width: 65%;" )
        el-date-picker(v-model="form.date" format="dd-MM-yyyy" :disabled="true", :default-value="defaulValue" type="date", :placeholder="$t('lang.transport.airportForm.selectDate')", :picker-options="datePickerOptions")
      el-form-item(prop="time", style="width: 35%;")
        el-time-picker(v-model="form.time", :id="'FlightTimeField-' + type" format="HH:mm", :disabled="form.airPort == '' || disabled", :placeholder="$t('lang.transport.airportForm.selectTime')", v-on:input.native="validateTime")
    span(v-html="$t('lang.transport.airportForm.msgAlertShuttle')" v-if="hotel.shuttleTimesIn.length > 0 && type === 'ARRIVAL' && farShuttle('IN')")
    span(v-html="$t('lang.transport.airportForm.msgAlertShuttle')" v-if="hotel.shuttleTimesOut.length > 0 &&type === 'DEPARTURE' && farShuttle('OUT')")
    //- .input-label(v-if="(hotel.shuttleTimesIn.length > 0 || hotel.shuttleTimesOut.length > 0) && textNew") {{ $t('lang.transport.airportForm.selectShuttle') }}
    //- el-form-item(prop="time", v-if="type === 'ARRIVAL' && hotel.shuttleTimesIn.length > 0 && textNew")
    //-   el-select(v-model="form.shuttleSelection" placeholder="")
    //-     el-option(v-for="item in hotel.shuttleTimesIn.map(sh => sh.slice(0, 5))", :key="item", :label="item", :value="item")
    //- el-form-item(prop="time", v-if="type === 'DEPARTURE' && hotel.shuttleTimesOut.length > 0 && textNew")
    //-   el-select(v-model="form.shuttleSelection" placeholder="")
    //-     el-option(v-for="item in hotel.shuttleTimesOut.map(sh => sh.slice(0, 5))", :key="item", :label="item", :value="item")
    .message(v-if="(form.time && isLate) || form.time === ''", v-html="lateFlightAtacama")
    blocked-msg(v-if="disabled")
    .button-group(v-else)
      el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
      el-button#explora-btn.primary(@click="checkTimeTransport()", :class="`save-${type}-data`") {{ $t('lang.control.button.continue') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import BlockedMsg from '../BlockedMsg.vue'

export default {
  props: ['type', 'changeStep', 'goBack', 'goBackPage', 'legNumber', 'isLast', 'addIndex', 'hotel', 'confirmationNumber', 'disabled', 'loadingRef'],
  components: {
    BlockedMsg
  },
  data () {
    return {
      form: {
        airPort: '',
        airLine: '',
        shuttleSelection: '',
        flightNumber: '',
        date: '',
        time: ''
      },
      partyApply: [],
      textNew: false,
      reservation: null,
      datePickerOptions: '',
      rules: {
        airPort: [{ required: true, message: this.$t('lang.transport.airportForm.airPortError'), trigger: 'blur' }],
        airLine: [{ required: true, message: this.$t('lang.transport.airportForm.airLineError'), trigger: 'blur' }],
        flightNumber: [{ required: true, message: this.$t('lang.transport.airportForm.flightNumberError'), trigger: 'blur' }],
        date: [{ required: true, message: this.$t('lang.transport.airportForm.dateError'), trigger: 'blur' }],
        time: [{ required: true, message: this.$t('lang.transport.airportForm.timeError'), trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['updateTransport', 'modifyTravellerStepStatus']),
    farShuttle(type) {
      const firstShuttleIn = this.hotel.shuttleTimesIn.length > 0 ? this.hotel.shuttleTimesIn[0] : null
      const firstShuttleOut = this.hotel.shuttleTimesOut.length > 0 ? this.hotel.shuttleTimesOut[0] : null
      if (this.form.time && this.form.time !== '') {
        if (type === 'IN') {
          if (firstShuttleIn) {
            const formatedFirtsShuttle = moment(firstShuttleIn, 'HH:mm').add(-30, 'minutes')
            const formatedFlightTime = moment(moment(this.form.time).format('HH:mm'), 'HH:mm')
            return formatedFirtsShuttle.isAfter(formatedFlightTime)
          }
        } else {
          if (firstShuttleOut) {
            const formatedLastShuttle = moment(firstShuttleOut, 'HH:mm').add(105, 'minutes')
            const formatedFlightTime = moment(moment(this.form.time).format('HH:mm'), 'HH:mm')

            return formatedLastShuttle.isAfter(formatedFlightTime)
          }
        }
      }

      return false
    },
    validateTime (event) {
      if (
        event.target.value &&
        event.target.value.length === 2 &&
        !event.target.value.includes(':') &&
        event.data !== null
      ) {
        event.target.value += ':'
      }
    },
    checkTimeTransport () {
      const flightTime = moment(moment(this.form.time).format('HH:mm'), 'HH:mm')
      let needPrivateTransport = this.availableSchedules.length > 0
      this.availableSchedules.forEach(schedule => {
        const start = moment(schedule.timeStart, 'HH:mm')
        const end = moment(schedule.timeEnd, 'HH:mm')
        if (flightTime.isBetween(start, end, null, '[]')) {
          needPrivateTransport = false
        }
      })
      if (needPrivateTransport) {
        this.$confirm(this.$t('lang.transport.scheduleWarning'), this.$t('lang.transport.scheduleWarningTitle'), {
          confirmButtonText: this.$t('lang.control.button.understood'),
          cancelButtonText: this.$t('lang.control.button.correct'),
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.saveDataAndContinue('form', true)
        }).catch(() => {})
      } else {
        this.saveDataAndContinue('form', false)
      }
    },
    async saveDataAndContinue (form, warning) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const transport = this.transportsInformation.find(t => t.cityCode === this.form.airPort && t.hotel.id === this.hotel.id && t.transportType === 'AEROPUERTO')
          const confirmationNumbers = [this.confirmationNumber]

          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, `${this.transportData.firstName} ${this.transportData.lastName}`))
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
               key: res.confirmationNumber
             },
             domProps: { value: reservationCheckVal.value },
             on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          let canContinue = true

          if (parseInt(this.transportData.reservationConsulted.partyCode) === this.confirmationNumber && this.transportData.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: this.$t('lang.applyTransportParty.message'),
              message: h('div', null, [
                h('p', null, this.$t('lang.applyTransportParty.description')),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              confirmButtonClass: 'apply-transport-info',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }

          if (canContinue) {
            this.loadingRef.showLoading();
            const nextStep = this.type === 'ARRIVAL' ? 2 : 3
            await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.transportData.idTraveller, stepNumber: nextStep, status: 'OK', confirmationNumber: this.confirmationNumber, legNumber: this.legNumber })
            if (this.isLast) {
              this.changeStep(nextStep)
            } else {
              this.addIndex()
            }
            const resp = await this.updateTransport({
              travellerId: this.transportData.idTraveller,
              confirmationNumbers: confirmationNumbers,
              transportId: `${this.form.flightNumber}`,
              transportType: transport.transportTypeOws,
              carrierCode: this.form.airLine,
              locationCode: transport.cityCode,
              date: `${moment(this.form.date).format('YYYY-MM-DD')} ${moment(this.form.time).format('HH:mm')}:00`,
              queryType: this.type,
              legNumber: this.legNumber,
              warning: warning,
              destinationType: 'AIRPORT',
              shuttleTime: this.form.shuttleSelection
            })
            this.loadingRef.hideLoading();
            if (resp === true) {
              this.$notify({
                title: this.$t('lang.saved.title'),
                type: 'success',
                customClass: 'step-transport-airport-complete'
              })
            } else {
              this.$notify({
                title: this.$t('lang.errorSave.title'),
                message: this.$t('lang.errorSave.message'),
                type: 'error'
              })
            }
          }
        } else {
          return false
        }
      })
    },
    GoBack () {
      this.goBack()
    }
  },
  computed: {
    ...mapGetters(['transportsInformation', 'transportData']),
    isLate () {
      const attrIn = this.textNew ? 'maxTimeInNew' : 'maxTimeIn'
      const attrOut = this.textNew ? 'maxTimeOutNew' : 'maxTimeOut'

      const hotelMaxTime = this.type === 'ARRIVAL' ? this.hotel[attrIn] : this.hotel[attrOut]

      const momentTime = moment(moment(this.form.time).format('HH:mm') + ':00', 'HH:mm:ss')
      const momentMaxTime = moment(hotelMaxTime, 'HH:mm:ss')

      return momentTime.isSameOrAfter(momentMaxTime)
    },
    lateFlightAtacama () {
      const lang = localStorage.getItem('lang')
      if (this.type === 'ARRIVAL') {
        if (lang && lang === 'en') return this.textNew ? this.hotel.transferMsgEnInNew : this.hotel.transferMsgEnIn
        if (lang && lang === 'pt') return this.textNew ? this.hotel.transferMsgPtInNew : this.hotel.transferMsgPtIn
        return this.textNew ? this.hotel.transferMsgEsInNew : this.hotel.transferMsgEsIn
      } else {
        if (lang && lang === 'en') return this.textNew ? this.hotel.transferMsgEnOutNew : this.hotel.transferMsgEnOut
        if (lang && lang === 'pt') return this.textNew ? this.hotel.transferMsgPtOutNew : this.hotel.transferMsgPtOut
        return this.textNew ? this.hotel.transferMsgEsOutNew : this.hotel.transferMsgEsOut
      }
    },
    airPorts () {
      return this.transportsInformation.filter(t => t.transportType.toLowerCase() === 'aeropuerto' && t.hotel.id === this.hotel.id && (this.type === 'ARRIVAL' && t.showIn || this.type === 'DEPARTURE' && t.showOut))
    },
    availableSchedules () {
      const airport = this.transportsInformation.find(t => t.cityCode === this.form.airPort)
      if (airport) {
        const transportType = this.type === 'ARRIVAL' ? 1 : 2
        return airport.transportSchedule.filter(ts => ts.scheduleType === transportType)
      }
      return []
    },
    airPortWarning () {
      if (this.availableSchedules.length > 0) {
        return (this.availableSchedules.length === 1 ? this.$t('lang.transport.availableSchedules') : this.$t('lang.transport.availableSchedulesPlural')) + ': ' + this.availableSchedules.map(ts => `${ts.timePickup.substring(0, 5)}`).join(', ')
      }
      return ''
    },
    defaulValue () {
      if (this.type === 'ARRIVAL') return new Date(this.transportData.reservationConsulted.checkin)
      else return new Date(this.transportData.reservationConsulted.checkout)
    },
    airlines () {
      const airport = this.transportsInformation.find(t => t.transportType.toLowerCase() === 'aeropuerto' && t.hotel.id === this.hotel.id && t.cityCode === this.form.airPort)
      if (airport) {
        return airport.airlines
      }
      return []
    }
  },
  created () {
    if (this.legNumber === this.transportData.reservationConsulted.legNumber && this.confirmationNumber === this.transportData.reservationConsulted.confirmationNumber) {
      this.reservation = this.transportData.reservationConsulted
    } else {
      this.reservation = this.transportData.aditionalReservations.find(r => r.legNumber === this.legNumber && r.confirmationNumber === this.confirmationNumber)
    }
    // Vamos a comparar checkin y fecha de creación
    if (this.hotel.creationDateSince && this.hotel.checkinDateSince) {
      const creationDateSince = moment(moment(this.hotel.creationDateSince).format('YYYY-MM-DD'), 'YYYY-MM-DD')
      const checkinDateSince = moment(moment(this.hotel.checkinDateSince).format('YYYY-MM-DD'), 'YYYY-MM-DD')
      const creationDate = moment(moment(this.reservation.creationDate).format('YYYY-MM-DD'), 'YYYY-MM-DD')
      const checkin = moment(moment(this.reservation.checkin).format('YYYY-MM-DD'), 'YYYY-MM-DD')

      this.textNew = creationDate.isSameOrAfter(creationDateSince) && checkin.isSameOrAfter(checkinDateSince)
    }
    if (this.type === 'ARRIVAL') {
      this.form.date = this.reservation.checkin
      if (this.reservation.arrivalDestinationType === 'AIRPORT') {
        this.form.airPort = this.reservation.arrivalTransportLocation
        this.form.airLine = this.reservation.arrivalTransportCarrier
        this.form.flightNumber = this.reservation.arrivalTransportId
        this.form.time = this.reservation.arrivalTransportDatetime
        this.form.shuttleSelection = this.reservation.shuttleTime
      }
    } else {
      this.form.date = this.reservation.checkout
      if (this.reservation.departureDestinationType === 'AIRPORT') {
        this.form.airPort = this.reservation.departureTransportLocation
        this.form.airLine = this.reservation.departureTransportCarrier
        this.form.flightNumber = this.reservation.departureTransportId
        this.form.time = this.reservation.departureTransportDatetime
      }
    }
    const self = this
    this.datePickerOptions = {
      disabledDate (date) {
        if (self.type === 'ARRIVAL') {
          const dateCheckinTomorrow = new Date(self.reservation.checkin)
          dateCheckinTomorrow.setDate(dateCheckinTomorrow.getDate() + 1)
          const dateCheckin = new Date(self.reservation.checkin)
          dateCheckin.setDate(dateCheckin.getDate() - 1)
          return date > dateCheckinTomorrow || date < dateCheckin
        } else {
          // const dateCheckoutTomorrow = new Date(self.reservation.checkout)
          // dateCheckoutTomorrow.setDate(dateCheckoutTomorrow.getDate() + 1)
          const dateCheckout = new Date(self.reservation.checkout)
          dateCheckout.setDate(dateCheckout.getDate() - 1)
          return date !== dateCheckout
        }
      }
    }
  }
}
</script>
<style lang="scss">
#AirportForm {
  .input-label {
    font-size: 12px;
    margin: 16px 0;
  }
  .title {
    display: flex;
  }
  .el-button--text {
    display: none;
  }
  .el-select {
    width: 100%;
  }
  .date-time-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
