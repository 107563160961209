<template lang="pug">
#Basic(v-if="travellerProfile")
  BlockedMsg(v-if="isBlocked")
  el-form.form(ref="form", :model="form", :rules="rules", v-else)

    .warning(v-html="text.TP_MAPI_STEP_INFO")

    .input-label {{ $t('lang.basicInformation.name') }}
    el-form-item(prop="name")
      el-input(v-model="form.name", ref="name", v-on:keypress.native="isLetter($event)")

    .input-label {{ $t('lang.basicInformation.lastName') }}
    el-form-item(prop="lastName")
      el-input(v-model="form.lastName", ref="lastName",  v-on:keypress.native="isLetter($event)")

    .input-label {{ $t('lang.basicInformation.birthDay') }}
    el-form-item(prop="birthdate")
      DatePicker(v-model="form.birthdate", ref="birthdate")

    .input-label {{ $t('lang.basicInformation.nationality') }}
    el-form-item(prop="nationality")
      el-select(v-model="form.nationality", filterable, :placeholder="$t('lang.basicInformation.nationalityPlaceholder')", style="width: 100%;")
        el-option(v-for="(n, index) in nationalities", v-bind:key="index", :label="n.description" :value="n.value")

    .special-form
      div(style="width: 45%")
        .input-label {{ $t('lang.basicInformation.documentType') }}
        el-input(v-model="form.docType", disabled)
      div(style="width: 45%")
        .input-label {{ $t('lang.basicInformation.document') }}
        el-form-item(prop="document")
          el-input(v-model="form.document", ref="document")

    .choose-date(v-html="text.TP_MAPI_DATE_INFO")

    el-form-item(prop="mapiDate")
      el-date-picker(
        v-model="form.mapiDate"
        format="dd-MM-yyyy"
        type="date"
        :picker-options="datePickerOptions"
        :default-value="defaultChosenDate"
        :disabled="hasTickets"
      )

    el-form-item(prop="confirmDate")
      el-checkbox.checkbox-wrap(
        v-model="form.confirmDate"
        ref="termsCheckedCovid"
        type="checkbox" id="terms"
      )
        div(v-html="text.TP_MAPI_DATE_CONFIRM")

    .button-group
      el-button#explora-btn.secondary(@click="saveData(goToPrevStep)" :disabled="!form.confirmDate") {{ $t('lang.control.button.goBack') }}
      el-button#explora-btn.primary(@click="saveData(goToNextStep)" :disabled="!form.confirmDate") {{ $t('lang.control.button.continue') }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import BlockedMsg from './BlockedMsg.vue'
import DatePicker from '../Utils/DatePicker.vue'
import { stepValues } from './formSteps'

export default {
  components: { BlockedMsg, DatePicker },
  props: {
    isBlocked: {
      type: Boolean,
      default: false,
    },
    changeStep: {
      type: Function,
      default: () => {},
    },
    loadingRef: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      text: {},
      form: {
        travellerId: null,
        name: '',
        lastName: '',
        birthdate: '',
        nationality: '',
        docType: this.$t('lang.basicInformation.dni'),
        document: '',
        mapiDate: null,
        confirmDate: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.name'),
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.lastName'),
            trigger: 'blur',
          },
        ],
        document: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.document'),
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.birthdate'),
            trigger: 'blur',
          },
        ],
        nationality: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.nationality'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'nationalities',
      'travellerProfile',
      'confirmationNumberToken',
      'confirmationNumber',
    ]),

    hasTickets() {
      const visit = this.reservation.mapiVisit
      return visit?.hasTicketParque || visit?.hasTicketTren
    },

    reservation() {
      return this.travellerProfile.reservationConsulted
    },

    defaultChosenDate() {
      return moment(this.reservation.checkout)
        .startOf('day')
        .subtract(1, 'days')
    },

    datePickerOptions() {
      return { disabledDate: this.disabledPickerDate }
    },
  },
  methods: {
    ...mapActions([
      'updateMapiPreferredDate',
      'modifyTravellerStepStatus',
      'updateTravellerDataMapi',
      'getDisplayMessages',
    ]),

    isLetter(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\s]*$/.test(char)) return true
      else e.preventDefault()
    },

    disabledPickerDate(date) {
      const { checkin, checkout } = this.reservation
      return !moment(date).isBetween(checkin, checkout, 'day', '()')
    },

    async saveData(goToCallback) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loadingRef.showLoading();

          const res = [
            await this.saveTravellerProfile(),
            await this.savePreferredDate(),
          ]
          this.loadingRef.hideLoading();
          if (res[0] && res[1]) {
            this.notifySuccess()
            goToCallback()
          } else {
            this.notifyError()
          }
        }
      })
    },

    async saveTravellerProfile() {
      return await this.updateTravellerDataMapi({
        id: this.travellerProfile.idTraveller,
        dni: this.form.document,
        name: this.form.name,
        lastName: this.form.lastName,
        birthdate: this.form.birthdate,
        nationality: this.form.nationality,
        confirmationNumber: this.$route.params.confirmationNumber,
      })
    },

    async savePreferredDate() {
      return await this.updateMapiPreferredDate({
        confirmationNumber: this.reservation.confirmationNumber,
        legNumber: this.reservation.legNumber,
        mapiPreferredDate: new Date(this.form.mapiDate)
          .toISOString()
          .split('T')[0],
      })
    },

    notifySuccess() {
      this.$notify({
        title: this.$t('lang.saved.title'),
        type: 'success',
      })
    },

    notifyError() {
      this.$notify({
        title: this.$t('lang.errorSave.title'),
        message: this.$t('lang.errorSave.message'),
        type: 'error',
      })
    },

    async goToPrevStep() {
      this.$emit('previousStep')
    },

    async goToNextStep() {
      await this.modifyTravellerStepStatus({
        token: this.$route.params.profileToken,
        travellerId: this.travellerProfile.idTraveller,
        stepNumber: stepValues.MAPI_VISIT,
        status: 'OK',
        confirmationNumber: this.reservation.confirmationNumber,
        legNumber: this.reservation.legNumber,
      })
      this.$emit('nextStep')
    },
  },

  watch: {
    defaultChosenDate: {
      handler(val) {
        this.form.mapiDate = this.defaultChosenDate
      },
      immediate: true,
    },

    hasTickets: {
      handler(val) {
        console.log('hasTickets:', val)
        if (val) this.form.confirmDate = true
      },
      immediate: true,
    },
  },

  async created() {
    this.form.name = this.travellerProfile.firstName
    this.form.lastName = this.travellerProfile.lastName
    this.form.birthdate =
      this.travellerProfile.birthday === null
        ? '2020-01-01T00:00:00.000Z'
        : this.travellerProfile.birthday
    this.form.nationality = this.travellerProfile.nationality
    this.form.document = this.travellerProfile.dni
    this.form.mapiDate = moment(this.reservation.mapiVisit?.preferredDate)
    this.text = await this.getDisplayMessages({
      codes: ['TP_MAPI_STEP_INFO', 'TP_MAPI_DATE_INFO', 'TP_MAPI_DATE_CONFIRM'],
    })
  },
}
</script>

<style lang="scss" scoped>
.warning {
  border: 1px solid #e6a23c;
  padding: 0 10px;
}

.choose-date {
  @extend .warning;
  margin-top: 20px;
  margin-bottom: 30px;
  border-color: #409eff;
}

.checkbox-wrap {
  ::v-deep .el-checkbox__label {
    white-space: normal;
    margin-right: 16px;
  }
}
</style>
