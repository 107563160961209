<template lang="pug">
#BlockedMsg
  //- Radio
  el-radio(v-if="reservation.packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH'].includes(pkc)).length > 0") {{ $t('lang.transport.connectTdpPlace') }}
  el-radio(v-else-if="reservation.packagesCodes.filter(pkc => ['3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0") {{ $t('lang.transport.connectPnpPlace') }}
  //- Mensajes
  .pnp-msg(v-if="reservation.packagesCodes.filter(pkc => ['3NPTNTCOCH', '4NPTNTCOCH'].includes(pkc)).length > 0") {{ tdpTransfer }}
  .pnp-msg(v-else-if="reservation.packagesCodes.filter(pkc => ['3NCOCHPTNT', '4NCOCHPTNT'].includes(pkc)).length > 0") {{ pnpTransfer }}
</template>
<script>
import moment from 'moment'

export default {
  props: ['reservation'],
  computed: {
    pnpTransfer () {
      return this.$t('lang.transport.connectPnp').replace('<DATE>', moment(this.reservation.checkin).format('DD/MM/YYYY'))
    },
    tdpTransfer () {
      return this.$t('lang.transport.connectTdp').replace('<DATE>', moment(this.reservation.checkin).format('DD/MM/YYYY'))
    }
  }
}
</script>
<style lang="scss">
#BlockedMsg {
  text-align: left;
  padding: 22px;
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
}
</style>
