<template lang="pug">
center
  div(v-if="file")
    .file
      img.image-preview(:src="previewImg" v-if="previewImg")
      label.float-change-image(:for="'input-file-' + id")
        .el-icon-refresh
  .file-upload(v-else)
    label(:for="'input-file-' + id")
      .el-icon-upload
      span {{ $t('lang.fileUpload') }}
  input(type="file" :ref="'input-file-' + id" :id="'input-file-' + id" @change="handleFileChange" style="display: none;")
</template>

<script>
export default {
  props: {
    value: File,
    id: {
      type: String
    },
    preview: {
      type: String
    }
  },
  computed: {
    previewImg() {
      if (this.preview && !this.imageChange) {
        // Si el archivo es PDF, MOSTRAR UNA IMAGEN DE PREVIEW (https://pgo-explora-back.s3.us-east-2.amazonaws.com/static/pdf-icon.png)
        if (this.preview.includes('.pdf') || this.preview.includes('.PDF')) {
          return 'https://pgo-explora-back.s3.us-east-2.amazonaws.com/static/pdf-icon.png';
        } else {
          return this.preview;
        }
      } else if (this.file && this.imageChange) {
        // Si el archivo es PDF, MOSTRAR UNA IMAGEN DE PREVIEW (https://pgo-explora-back.s3.us-east-2.amazonaws.com/static/pdf-icon.png)
        if (this.file.type === 'application/pdf') {
          return 'https://pgo-explora-back.s3.us-east-2.amazonaws.com/static/pdf-icon.png';
        } else {
          return URL.createObjectURL(this.file);
        }
      }
      return null;
    }
  },
  data() {
    return {
      file: null,
      imageChange: false,
    };
  },
  watch: {
    value(newFile) {
      this.file = newFile;
    }
  },
  methods: {
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (this.isFileTypeAllowed(selectedFile)) {
          this.file = selectedFile;
          this.$emit('input', selectedFile);
          this.imageChange = true;
        } else {
          console.error('Tipo de archivo no permitido. Por favor, elige un archivo PDF, PNG, JPG o JPEG.');
          this.$notify({
            title: this.$t('lang.attachments.problem'),
            message: this.$t('lang.attachments.unsupportedFileError'),
            type: 'error'
          })
        }
      }
    },
    isFileTypeAllowed(file) {
      const allowedTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
      return allowedTypes.includes(file.type);
    },
  },
};
</script>

<style scoped lang="scss">
.file-upload, .image-preview {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 10px;
  margin: 10px;
}
.file-upload {
  border: 2px dashed #aaa;
  border-radius: 8px;
}
.float-change-image {
  // QUIERO QUE FLOTE A LA ESQUINA INFERIOR DECHA DE LA IMAGEN
  position: absolute;
  background-color: #303133;
  color: white;
  border-radius: 50%;
  padding: 12px;
  margin-left: 150px;
  margin-top: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 0.3s;
}
.image-preview {
  object-fit: contain;
  border: 1px solid #aaa;
  border-radius: 8px;
}
.el-icon-upload {
  font-size: 70px;
}
.file {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-icon-close {
  margin-left: 6px;
  color: #c32020
}
.file-name {
  font-style: italic;
}
label {
  display: grid;
}
</style>
