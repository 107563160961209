import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TravellerProfile from '../pages/TravellerProfile.vue'
import Form from '../pages/Form.vue'
import Checkin from '../pages/Checkin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/travellerProfile/:token',
    name: 'Perfil del viajero',
    component: TravellerProfile
  },
  {
    path: '/travellerProfile/modifyProfile/:profileToken/confirmationNumber/:confirmationNumber',
    name: 'Editar información viajero',
    component: Form
  },
  {
    path: '/travellerProfile/checkin/:profileToken/confirmationNumber/:confirmationNumber',
    name: 'Web Checkin',
    component: Checkin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
