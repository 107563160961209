<template lang="pug">
#CheckinFinished
  h2(v-if="hasPdiFile") {{ $t('lang.checkin.ready') }}
  div(v-else)
    h2 {{ $t('lang.checkin.pdiWarning') }}
    h4 {{ $t('lang.checkin.pdiWarningDescription') }}
    //- h4 {{ $t('lang.checkin.pdiWarningDescription2') }}
  el-button#explora-btn.primary(@click="goBack", :loading="loading") {{ $t('lang.control.button.backToTP') }}
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['travellerProfileLink', 'reservation', 'allReservations', 'hasPdiFile']),
    isLast() {
      return this.seletedIndex === this.allReservations.length - 1
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    goBack() {
      this.loading = true
      window.location.replace(this.travellerProfileLink)
    },
  }
}
</script>
<style lang="scss">
#CheckinFinished {
  padding: 0 24px;
} 
</style>