import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locals/en_US'
import es from './locals/es_ES'
import pt from './locals/pt_BR'

Vue.use(VueI18n)
let lang = 'en'

const selectedLang = localStorage.getItem('lang')
if (selectedLang) {
  lang = selectedLang
} else {
  localStorage.setItem('lang', lang)
}

export default new VueI18n({
  locale: lang,
  messages: {
    en: {
      lang: en
    },
    es: {
      lang: es
    },
    pt: {
      lang: pt
    }
  }
})
