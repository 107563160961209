<template lang="pug">
#TravellerInterests
  h1 {{ $t('lang.interests.interestsTitle') }}
  h2(v-if="interestsComo.length > 0") {{ $t('lang.interests.comoDescription') }}
  .icons
    div(v-for="i in interestsComo", v-bind:key="i.id")
      input(type="checkbox", :id="'interest-check-' + i.id", :value="i.code"  v-model="checkedComo" :disabled="isBlocked")
      label(:for="'interest-check-' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2(v-if="interestsList.length > 0") {{ $t('lang.interests.InterestsDescriptions') }}
  .icons
    div(v-for="i in interestsList", v-bind:key="i.id")
      input(type="checkbox", :id="'interest-check-' + i.id", :value="i.code"  v-model="checkedInterests" :disabled="isBlocked")
      label(:for="'interest-check-' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2(v-if="interestsQueEsperas.length > 0") {{ $t('lang.interests.queEsperasDescription') }}
  .icons
    div(v-for="i in interestsQueEsperas", v-bind:key="i.id")
      input(type="checkbox", :id="'interest-check-' + i.id", :value="i.code"  v-model="checkedQueEsperas" :disabled="isBlocked")
      label(:for="'interest-check-' + i.id",)
        .icon-circle
          font-awesome-icon(:icon="['fas', 'hiking']", v-if="i.iconName === null", size="2x")
          font-awesome-icon(:icon="['fab', i.iconName]", v-if="i.iconName === 'pagelines'", size="2x")
          font-awesome-icon(:icon="['fas', i.iconName]", v-if="i.iconName !== null && i.iconName !== 'pagelines'", size="2x")
      h6 {{ i.name }}
  h2 {{ $t('lang.interests.comentary') }}
  el-input(v-model="commentary" :disabled="isBlocked")
  blocked-msg(v-if="isBlocked")
  .button-group(v-else)
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="saveData" class="saveInterestBtn") {{ $t('lang.control.button.continue') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BlockedMsg from './BlockedMsg.vue'
import { stepValues } from './formSteps'

export default {
  props: ['changeStep', 'isBlocked', 'includesMapi', 'loadingRef'],
  components: {
    BlockedMsg
  },
  data () {
    return {
      checkedComo: [],
      checkedInterests: [],
      checkedQueEsperas: [],
      interestsComo: [],
      interestsList: [],
      interestsQueEsperas: [],
      commentary: ''
    }
  },
  computed: {
    ...mapGetters(['interests', 'travellerProfile', 'confirmationNumber'])
  },
  methods: {
    ...mapActions(['modifyTravellerStepStatus', 'updateTravellerInterests']),
    async saveData () {
      this.loadingRef.showLoading();
      this.$emit('nextStep')
      const interests = []
      this.checkedComo.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })
      this.checkedInterests.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })
      this.checkedQueEsperas.forEach(interest => {
        const newInterest = { interestCode: interest }
        interests.push(newInterest)
      })

      await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.travellerProfile.idTraveller, confirmationNumber: this.travellerProfile.reservationConsulted.confirmationNumber, stepNumber: stepValues.INTERESTS, status: 'OK' })
      const resp = await this.updateTravellerInterests({ travellerId: this.travellerProfile.idTraveller, confirmationNumber: this.travellerProfile.reservationConsulted.confirmationNumber, travellerInterests: interests, commentary: this.commentary })
      this.loadingRef.hideLoading();
      if (resp === true) {
        this.$notify({
          title: this.$t('lang.saved.title'),
          type: 'success',
          customClass: 'step-interest-complete'
        })
      } else {
        this.$notify({
          title: this.$t('lang.errorSave.title'),
          message: this.$t('lang.errorSave.message'),
          type: 'error'
        })
      }
    },
    goBack () {
      this.$emit('previousStep')
    }
  },
  created () {
    this.commentary = this.travellerProfile.reservationConsulted.interestComment
    this.interestsComo = this.interests.filter(interest => interest.clasification === 'COMO')
    this.interestsList = this.interests.filter(interest => interest.clasification === 'INTERES')
    this.interestsQueEsperas = this.interests.filter(interest => interest.clasification === 'QUE_ESPERAS')

    const como = this.travellerProfile.interests.filter(interest => interest.clasification === 'COMO')
    como.forEach(interest => {
      this.checkedComo.push(interest.code)
    })
    const interests = this.travellerProfile.interests.filter(interest => interest.clasification === 'INTERES')
    interests.forEach(interest => {
      this.checkedInterests.push(interest.code)
    })
    const queEsperas = this.travellerProfile.interests.filter(interest => interest.clasification === 'QUE_ESPERAS')
    queEsperas.forEach(interest => {
      this.checkedQueEsperas.push(interest.code)
    })
  }
}
</script>
<style lang="scss">
#TravellerInterests{
  text-align: left;
  padding: 8px 16px;
  h6 {
    margin-top: 0px !important;
  }
  input[type="checkbox"][id^="interest-check-"] {
    display: none;
  }
  .icons {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .icon-circle {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dbdde0;
    color: #4b5763;
  }
  label {
    margin-bottom: 1px !important;
  }
  @media (max-width: 375px) {
    .icon-circle {
      width: 67px;
      height: 67px;
    }
    label {
      margin: 9px !important;
    }

    label:before {
      top: 51px !important;
      left: 59px !important;
    }
  }
  @media (max-width: 320px) {
    .icon-circle {
      width: 50px;
      height: 50px;
    }

    label:before {
      top: 38px !important;
      left: 44px !important;
    }
  }
  label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #f7f1f1;
    position: absolute;
    top: 67px;
    left: 73px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  :checked + label:before {
    content: "✓";
    background-color: rgb(58, 214, 118);
    transform: scale(1);
  }
}
</style>
