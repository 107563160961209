import firebase from 'firebase/app';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyCTztcJKd6Hb0Gr_FhcfuTW4NeCWoFNnW8",
    authDomain: "test-explora-8fc0a.firebaseapp.com",
    databaseURL: "https://test-explora-8fc0a.firebaseio.com",
    projectId: "test-explora-8fc0a",
    storageBucket: "",
    messagingSenderId: "367217471097",
    appId: "1:367217471097:web:6e8e8f059ecfdd30986ea8",
    measurementId: "G-VNR9HN2EF7"
};
firebase.initializeApp(config);
