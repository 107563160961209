import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'

export const getTravellers = async ({ token }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($token: String!) {
        reservationTravellersProfile(token: $token) {
          group
          titular
          reservations {
            id
            confirmationNumber
            legNumber
            checkin
            conafFile
            mobilityPass
            isCompletedMin
            reservationStatus
            stayDays
            partyCode
            aditionalReservations {
              checkin
              reservationStatus
            }
            hotel {
              id
              code
              needsMobilityPass
              daysBeforeCheckin
              daysBeforeCheckinTransport
              webCheckinEnabled
            }
            steps {
              id
              step
              status
              confirmationNumber
              legNumber
            }
            travellerObj {
              id
              token
              firstName
              lastName
              email
              birthday
              avatarUrl
              lang
            }
          }
        }
        displayMessages {
          code textEs textEn textPt
        }
      }
    `,
    variables: {
      token: token
    }
  })
  return response.data
}



export const getTerms = async ({ termsType }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        termsGeneral (termsType: "${termsType}", lang: "${localStorage.getItem('lang')}")
      }
    `,
    variables: null
  })

  return response.data.termsGeneral
}

export const getTravellerProfile = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "${localStorage.getItem('lang')}", sync: true) {
          idTraveller
          aboutUsAnswer
          aboutUsImportantFactorAnswer
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            steps {
              id
              step
              legNumber
              status
            }
            hotel {
              id
              name
              daysBeforeCheckinTransport
            }
            travellerType
            checkin
            checkout
            reservationStatus
            confirmationNumber
            roomTypeDesc
            roomType2
          }
          aditionalReservations(confirmationNumber: $confirmationNumber) {
            hotel {
              id
              name
              daysBeforeCheckinTransport
            }
            checkin
            checkout
            reservationStatus
            confirmationNumber
            roomTypeDesc
            roomType2
          }
        }
      }
    `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  return response.data
}

export const getTransportData = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "${localStorage.getItem('lang')}") {
          idTraveller
          firstName
          lastName
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            confirmationNumber
            legNumber
            creationDate
            steps {
              id
              step
              legNumber
              status
            }
            hotel {
              id
              name
              howToArriveFileEs
              howToArriveFileEn
              howToArriveFilePt
              creationDateSince
              locations {
                id
                name
                locationCode
              }
              shuttleTimesIn
              shuttleTimesOut
            }
            partyConfirmations {
              confirmationNumber
              travellerObj {
                id
                firstName
                lastName
              }
            }
            howArrive
            checkin
            checkout
            partyCode
            travellerType
            crossingGroup
            packagesCodes
            travellerType
            arrivalTransportId,
            arrivalTransportLocation,
            arrivalTransportCarrier,
            arrivalTransportType,
            arrivalTransportDatetime,
            arrivalStatus,
            arrivalOtherHotel,
            arrivalDestinationType,
            departureTransportId,
            departureTransportLocation,
            departureTransportCarrier,
            departureTransportType,
            departureTransportDatetime,
            departureStatus,
            departureDestinationType,
            departureOtherHotel,
            departureStatus
            departureDestinationType
            reservationStatus
          }
          aditionalReservations(confirmationNumber: $confirmationNumber) {
            confirmationNumber
            legNumber
            creationDate
            hotel {
              id
              name
              howToArriveFileEs
              howToArriveFileEn
              howToArriveFilePt
              creationDateSince
              shuttleTimesIn
              shuttleTimesOut
              locations {
                id
                name
                locationCode
              }
            }
            howArrive
            specialTransportCrossing
            checkin
            checkout
            crossingGroup
            packagesCodes
            travellerType
            arrivalTransportId,
            arrivalTransportLocation,
            arrivalTransportCarrier,
            arrivalTransportType,
            arrivalTransportDatetime,
            arrivalStatus,
            arrivalOtherHotel,
            arrivalDestinationType,
            departureTransportId,
            departureTransportLocation,
            departureTransportCarrier,
            departureTransportType,
            departureTransportDatetime,
            departureStatus,
            departureDestinationType,
            departureOtherHotel,
            departureStatus
            departureDestinationType
            reservationStatus
          }
        }
      }
    `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  return response.data
}

export const getBasicInformation = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.query({
    query: gql`
      query ($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "es") {
          idTraveller
          dni
          firstName
          lastName
          gender
          birthday
          nationality
          physicalState
          fitnessIntensity
          hasFoodReq
          hasMedicalReq
          foodRestrictions
          otherMedicalConditions
          avatarUrl
          nameEmergency
          address {
            country
            region
            zipCode
            operaId
            city
            address
          }
          contacts {
            operaId
            contactType
            contactRole
            value
          }
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            termsTsrAccepted
            termsConnectsAccepted
            exploraConnectId
            packagesCodes
            hotel {
              termsAndConditionsTsr
              termsAndConditionsConnect
            }
            partyConfirmations {
              confirmationNumber
              travellerObj {
                id
                firstName
                lastName
              }
            }
            steps {
              id
              step
              legNumber
              status
              __typename
            }
          }
        }
      }
      `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber,
    }
  })

  return response.data
}

export const getDietInformation = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.query({
    query: gql`
      query ($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "es") {
          idTraveller
          foodRequirements {
            id
            code
            name
            haveRelation
          }
          medicalConditions {
            id
            code
            name
            haveRelation
          }
          tags {
            id,
            category,
            subCategory,
            value
          }
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            confirmationNumber
          }
          foodRestrictions
          otherMedicalConditions
          hasFoodReq
          hasMedicalReq
        }
      }
      `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  return response.data
}



export const getTravellerProfileLink = async ({ token, confirmationNumber }) => {
  const reservationsRequest = `
    idReservation
    confirmationNumber
    partyCode
    linkTravellerProfile
    entryDatePeru
    hotel {
      code
      name
    }
    travellerObj {
      id
      age
      isResidentChile
      isResidentArgentina
      isResidentPeru
    }
    pdiFileLink
    passportFileLink
    pdiFilePreviewLink
    passportFilePreviewLink
    waiverFile
    isSigned
  `
  const response = await apolloClient.query({
    query: gql`
      query($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "${localStorage.getItem('lang')}") {
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            ${reservationsRequest}
            aditionalReservations {
              ${reservationsRequest}
            }
          }
        }
      }
    `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  console.log(response)

  return response.data
}

export const getTravellersTeenagersCompanion = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($confirmationNumber: Int, $token: String) {
        travellersTeenagersCompanion(confirmationNumber: $confirmationNumber, token: $token) {
          id
          firstName
          lastName
          birthday
        }
      }
    `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  return response.data
}

export const getNationalities = async ({ lang }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
    query {
      nationalities(lang: "${lang}") {
        value
        description
      }
    }
    `,
    variables: null
  })

  return response.data
}

export const getInformation = async ({ informationType, countryCode }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($informationType: String!, $countryCode: String!) {
        information(informationType: $informationType, countryCode: $countryCode) {
          value,
          description
        }
      }
    `,
    variables: {
      informationType: informationType,
      countryCode: countryCode
    }
  })

  return response.data
}

export const modifyStepStatus = async ({ travellerId, stepNumber, status, confirmationNumber, legNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation($travellerId: ID!, $stepNumber: Int!, $stepStatus: String!, $confirmationNumber: Int!, $legNumber: Int!) {
        ModifyStepStatus(travellerId: $travellerId, stepNumber: $stepNumber, stepStatus: $stepStatus, confirmationNumber: $confirmationNumber, legNumber: $legNumber){
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      stepNumber: stepNumber,
      stepStatus: status,
      confirmationNumber: confirmationNumber,
      legNumber: legNumber
    }
  })

  return response.data
}

export const saveTravellerProfileData = async ({
  id, dni, name, lastName, gender, birthdate, nationality,
  country, state, city, zipCode, address, email, nameEmergency, emailEmergency, phoneNumber, phoneNumberEmergency,
  operaIdEmail, operaIdAddress, operaIdPhoneNumber,
  operaIdPhoneNumberEmergency, operaIdEmailEmergency, confirmationNumber, babySeat, saveAlsoTo,
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $dni: String!,
        $confirmationNumber: Int!,
        $name: String!,
        $lastName: String!,
        $gender: String!,
        $birthdate: String!,
        $nationality: String!,
        $country: String!,
        $state: String!,
        $city: String!,
        $zipCode: String!,
        $address: String!,
        $email: String!,
        $nameEmergency: String,
        $emailEmergency: String,
        $phoneNumber: String!,
        $phoneNumberEmergency: String,
        $operaIdEmail: String!,
        $operaIdAddress: String!,
        $operaIdPhoneNumber: String!,
        $operaIdPhoneNumberEmergency: String!,
        $operaIdEmailEmergency: String!,
        $babySeat: String
        $saveAlsoTo: [ID]
        ) {
        UpdateTravellerProfile(
          travellerProfile: {
            id: $id,
            dni: $dni,
            confirmationNumber: $confirmationNumber
            name: $name,
            lastName: $lastName,
            gender: $gender,
            birthdate: $birthdate,
            nationality: $nationality,
            country: $country,
            state: $state,
            city: $city,
            zipCode: $zipCode,
            address: $address,
            email: $email,
            nameEmergency: $nameEmergency,
            emailEmergency: $emailEmergency,
            phoneNumber: $phoneNumber,
            phoneNumberEmergency: $phoneNumberEmergency,
            operaIdEmail: $operaIdEmail,
            operaIdAddress: $operaIdAddress,
            operaIdPhoneNumber: $operaIdPhoneNumber,
            operaIdPhoneNumberEmergency: $operaIdPhoneNumberEmergency,
            operaIdEmailEmergency: $operaIdEmailEmergency,
            babySeat: $babySeat,
            saveAlsoTo: $saveAlsoTo,
          }
        ){
          result
          errors
        }
      }
    `,
    variables: {
      id: id,
      dni: dni,
      confirmationNumber: confirmationNumber,
      name: name,
      lastName: lastName,
      gender: gender,
      birthdate: birthdate,
      nationality: nationality,
      country: country,
      state: state,
      city: city,
      zipCode: zipCode,
      address: address,
      email: email,
      nameEmergency: nameEmergency,
      emailEmergency: emailEmergency,
      phoneNumber: phoneNumber,
      phoneNumberEmergency: phoneNumberEmergency,
      operaIdEmail: operaIdEmail,
      operaIdAddress: operaIdAddress,
      operaIdPhoneNumber: operaIdPhoneNumber,
      operaIdPhoneNumberEmergency: operaIdPhoneNumberEmergency,
      operaIdEmailEmergency: operaIdEmailEmergency,
      babySeat: babySeat,
      saveAlsoTo,
    }
  })

  return response.data
}

export const saveTravellerConditions = async ({
  travellerId,
  hasFoodReq, hasMedicalReq,
  foodRequirements,
  medicalConditions,
  foodAllergiesTags,
  medicalDiseaseTags,
  medicalInjuryTags,
  medicalSurgeryTags,
  medicalAllergiesTags,
  confirmationNumber,
  foodRestrictions,
  otherMedicalConditions
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $hasFoodReq: Boolean,
        $hasMedicalReq: Boolean,
        $foodRequirements: [ConditionsInput],
        $medicalConditions: [ConditionsInput],
        $foodAllergiesTags: [TagInput],
        $medicalDiseaseTags: [TagInput],
        $medicalInjuryTags: [TagInput],
        $medicalSurgeryTags: [TagInput],
        $medicalAllergiesTags: [TagInput],
        $confirmationNumber: Int!,
        $foodRestrictions: String!,
        $otherMedicalConditions: String!
      ) {
        UpdateTravellerConditions(
          travellerConditions: {
            id: $id,
            hasFoodReq: $hasFoodReq,
            hasMedicalReq: $hasMedicalReq,
            foodRequirements: $foodRequirements,
            medicalConditions: $medicalConditions,
            foodAllergiesTags: $foodAllergiesTags,
            medicalDiseaseTags: $medicalDiseaseTags,
            medicalInjuryTags: $medicalInjuryTags,
            medicalSurgeryTags: $medicalSurgeryTags,
            medicalAllergiesTags: $medicalAllergiesTags,
            foodRestrictions: $foodRestrictions,
            otherMedicalConditions: $otherMedicalConditions
          }
          confirmationNumber: $confirmationNumber
        ){
          result
        }
      }
    `,
    variables: {
      id: travellerId,
      hasFoodReq: hasFoodReq,
      hasMedicalReq: hasMedicalReq,
      foodRequirements: foodRequirements,
      medicalConditions: medicalConditions,
      foodAllergiesTags: foodAllergiesTags,
      medicalDiseaseTags: medicalDiseaseTags,
      medicalInjuryTags: medicalInjuryTags,
      medicalSurgeryTags: medicalSurgeryTags,
      medicalAllergiesTags: medicalAllergiesTags,
      confirmationNumber: confirmationNumber,
      foodRestrictions: foodRestrictions,
      otherMedicalConditions: otherMedicalConditions
    }
  })

  return response.data
}

export const getTagsConditions = async () => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        tagsConditions{
          id,
          category,
          subCategory,
          value
        }
      }
    `,
    variables: null
  })
  return response.data
}

export const saveTravellerFitnessConditions = async ({
  travellerId,
  confirmationNumber,
  physicalState,
  fitnessIntensity
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!,
        $physicalState: Int!,
        $fitnessIntensity: Int!
      ) {
        UpdatePhysicalConditions(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber,
          physicalState: $physicalState,
          fitnessIntensity: $fitnessIntensity
        ){
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      physicalState: physicalState,
      fitnessIntensity: fitnessIntensity
    }
  })

  return response.data
}

export const getInterest = async ({ confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        travellerInterests(confirmationNumber: ${confirmationNumber}, languaje: "${localStorage.getItem('lang')}"){
          id,
          code,
          name,
          iconName,
          clasification
        }
      }
    `,
    variables: null
  })
  return response.data
}

export const getHotelTransports = async ({ confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
    query {
      hotelTransportInformation(confirmationNumber: ${confirmationNumber}){
        id,
        locationCode,
        transportType,
        value,
        transportTypeOws,
        cityCode,
        hasCharges
        location {
          id
        },
        hotel {
          id
        },
        transportSchedule {
          timeStart
          timeEnd
          timePickup
          scheduleType
        }
        airlines {
          code
          name
        }
        showIn
        showOut
      }
    }
    `,
    variables: null
  })
  return response.data
}

export const getLocations = async () => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        location {
          id,
          name,
          locationCode,
        }
      }
    `,
    variables: null
  })
  return response.data
}

export const updateTransportRequirements = async ({
  travellerId,
  confirmationNumbers,
  transportId,
  transportType,
  carrierCode,
  locationCode,
  otherHotel,
  date,
  queryType,
  legNumber,
  warning,
  destinationType,
  shuttleTime
}) => {
  console.log(shuttleTime)
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumbers: [Int]!
        $transportId: String!,
        $transportType: String!,
        $carrierCode: String!,
        $locationCode: String!,
        $otherHotel: String,
        $date: String!,
        $queryType: String!,
        $legNumber: Int!,
        $warning: Boolean!,
        $destinationType: String!,
        $shuttlePreference: String,
      ) {
        ModifyTransportRequirements(
          travellerId: $travellerId,
          confirmationNumbers: $confirmationNumbers,
          legNumber: $legNumber,
          warning: $warning,
          transportInformation: {
            transportId: $transportId,
            transportType: $transportType,
            carrierCode: $carrierCode,
            locationCode: $locationCode,
            otherHotel: $otherHotel,
            date: $date,
            queryType: $queryType,
            destinationType: $destinationType
            shuttlePreference: $shuttlePreference
          }
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumbers: confirmationNumbers,
      transportId: transportId,
      transportType: transportType,
      carrierCode: carrierCode,
      locationCode: locationCode,
      otherHotel: otherHotel,
      date: date,
      queryType: queryType,
      legNumber: legNumber,
      warning: warning,
      destinationType: destinationType,
      shuttlePreference: shuttleTime
    }
  })
  return response.data
}

export const ReservationCancellTransport = async ({
  travellerId,
  confirmationNumbers,
  legNumber,
  queryType,
  checkin,
  howArrive,
  arrivesToLunch
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumbers: [Int]!,
        $legNumber: Int!,
        $queryType: String!,
        $checkin: String,
        $howArrive: String,
        $arrivesToLunch: Boolean!
      ) {
        CancellTransport(
          travellerId: $travellerId,
          confirmationNumbers: $confirmationNumbers,
          legNumber: $legNumber,
          queryType: $queryType,
          checkin: $checkin,
          howArrive: $howArrive,
          arrivesToLunch: $arrivesToLunch,
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumbers: confirmationNumbers,
      legNumber: legNumber,
      queryType: queryType,
      checkin: checkin,
      howArrive: howArrive,
      arrivesToLunch: arrivesToLunch
    }
  })
  return response.data
}

export const updateInterests = async ({
  travellerId,
  confirmationNumber,
  travellerInterests,
  commentary
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!
        $travellerInterests: [TravellerInterests]
        $commentary: String!
      ) {
        UpdateTravellerInterests(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber
          travellerInterests: $travellerInterests
          commentary: $commentary
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      travellerInterests: travellerInterests,
      commentary: commentary
    }
  })
  return response.data
}

export const updateAboutUsAnswer = async ({
  travellerId,
  confirmationNumber,
  aboutUsValue,
  importantFactorValue,
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!,
        $aboutUsValue: String!,
        $importantFactorValue: String!,
      ) {
        UpdateAboutUsAnswer(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber,
          aboutUsValue: $aboutUsValue
          importantFactorValue: $importantFactorValue
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      aboutUsValue: aboutUsValue,
      importantFactorValue: importantFactorValue,
    }
  })
  return response.data
}


export const mutationUpdateMapiPreferredDate = async ({
  mapiPreferredDate,
  confirmationNumber,
  legNumber,
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $confirmationNumber: Int!
        $legNumber: Int!,
        $mapiPreferredDate: Date!,
      ) {
        UpdateMapiPreferredDate(
          confirmationNumber: $confirmationNumber,
          legNumber: $legNumber,
          mapiPreferredDate: $mapiPreferredDate,
        ) { result }
      }
    `,
    variables: {
      mapiPreferredDate: mapiPreferredDate,
      confirmationNumber: confirmationNumber,
      legNumber: legNumber,
    }
  })
  return response.data
}

export const mutationUpdateTravellerDataMapi = async ({
  confirmationNumber, id, dni, name, lastName, birthdate, nationality,
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $dni: String!,
        $confirmationNumber: Int!,
        $name: String!,
        $lastName: String!,
        $birthdate: String!,
        $nationality: String!,
      ) {
        UpdateTravellerDataMapi(
          id: $id,
          dni: $dni,
          confirmationNumber: $confirmationNumber,
          name: $name,
          lastName: $lastName,
          birthdate: $birthdate,
          nationality: $nationality,
        ) { result }
      }
    `,
    variables: {
      id: id,
      dni: dni,
      confirmationNumber: confirmationNumber,
      name: name,
      lastName: lastName,
      birthdate: birthdate,
      nationality: nationality,
    }
  })

  return response.data
}

export const queryGetDisplayMessages = async ({ codes }) => {
  const res = await apolloClient.query({
    query: gql`
      query ($codes: [String]) {
        displayMessages (codes: $codes ) {
          code textEs textEn textPt
        }
      }
    `,
    variables: {
      codes: [],
    }
  })
  let lang = localStorage.getItem('lang')
  lang = lang.charAt(0).toUpperCase() + lang.slice(1)
  return res.data.displayMessages.reduce((acc, msg) => {
    acc[msg.code] = msg[`text${lang}`]
    return acc
  })
}
