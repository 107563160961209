<template lang="pug">
#HotelForm
  h3(v-if="type === 'ARRIVAL'") {{ $t('lang.transport.hotelForm.descriptionArrival') }}
  h3(v-if="type === 'DEPARTURE'") {{ $t('lang.transport.hotelForm.descriptionDeparture') }}
  el-form(ref="form", :model="form", :rules="rules")
    .input-label {{ $t('lang.transport.hotelForm.location') }}
    el-form-item(prop="location")
      el-select(v-model="form.location" :disabled="disabled" filterable :placeholder="$t('lang.transport.hotelForm.selectCity')" style="width: 100%;" value-key="id" id="locationField")
        el-option(v-for="l in hotelLocations" :key="l.id", :label="l.name" :value="l" :id="'location-' + l.id")
        //- el-option(:label="$t('lang.transport.hotelForm.other')" value="OTRO")

    .input-label(v-if="form.location") {{ $t('lang.transport.hotelForm.hotel') }}
    el-form-item(prop="hotel", v-if="form.location")
      el-select(v-model="form.hotel", :disabled="disabled" filterable, :placeholder="$t('lang.transport.hotelForm.selectHotel')", style="width: 100%;" id="hotelSelectionField", @change="changeLocation")
        el-option(v-for="h in hotels", :key="h.id", :label="h.value" :value="h.locationCode", :id="'hotel-' + h.id")
        el-option(:label="$t('lang.transport.hotelForm.other')" value="OTRO" id="hotel-otro")
      .message(v-if="form.hotel === 'OTRO'", style="padding: 0;") {{ $t('lang.transport.hotelWarning') }}

    div(v-if="form.hotel === 'OTRO'")
      .input-label {{ $t('lang.transport.hotelForm.specify') }}
      el-form-item(prop="otherHotel")
        el-input(v-model="form.otherHotel", :disabled="disabled" placeholder="Nombre del hotel", id="hotelOtroField")

    //- el-card.box-card(v-if="type === 'ARRIVAL'")
    //-   p {{ $t('lang.transport.hotelForm.pgoMessage') }}
    blocked-msg(v-if="disabled")
    .button-group(v-else)
      el-button#explora-btn.secondary(@click="goBackPage") {{ $t('lang.control.button.goBack') }}
      el-button#explora-btn.primary(@click="checkOtherHotel()" :class="`save-${type}-data`") {{ $t('lang.control.button.continue') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BlockedMsg from '../BlockedMsg.vue'

export default {
  props: ['type', 'changeStep', 'goBack', 'goBackPage', 'legNumber', 'isLast', 'addIndex', 'location', 'hotel', 'dateTransport', 'confirmationNumber', 'disabled', 'loadingRef'],
  components: {
    BlockedMsg
  },
  data () {
    return {
      form: {
        location: null,
        hotel: '',
        otherHotel: ''
      },
      partyApply: [],
      rules: {
        location: [{ required: true, message: 'No dejes ciudad vacío', trigger: 'blur' }],
        hotel: [{ required: true, message: 'No dejes hotel vacío', trigger: 'blur' }],
        otherHotel: [{ required: true, message: 'No dejes hotel vacío', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['transportsInformation', 'transportData']),
    hotelLocations () {
      return this.hotel.locations
    },
    hotels () {
      if (!this.form.location) return []
      return this.transportsInformation.filter(t =>
        t.transportType === 'HOTEL'
        && t.hotel.id === this.hotel.id
        && t.location.id === this.form.location.id
        && (this.type === 'ARRIVAL' && t.showIn || this.type === 'DEPARTURE' && t.showOut)
      )
    }
  },
  watch: {
    'form.location' (val) {
      this.form.hotel = null
    },
  },
  methods: {
    ...mapActions(['updateTransport', 'modifyTravellerStepStatus']),
    changeLocation (val) {
      const hotel = this.hotels.find((htl) => htl.locationCode === val)
      if (hotel && hotel.hasCharges) {
        this.$confirm(this.$t('lang.transport.hotelChargesWarningDescription'), this.$t('lang.transport.hotelWarningTitle'), {
          confirmButtonText: this.$t('lang.control.button.understood'),
          cancelButtonText: this.$t('lang.control.button.correct'),
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          console.log('Seleccionado')
        }).catch(() => {
          this.form.hotel = null
        })
      }
    },
    checkOtherHotel () {
      const haveOtherHotel = this.form.hotel === 'OTRO'
      if (haveOtherHotel) {
        this.$confirm(this.$t('lang.transport.hotelWarningDescription'), this.$t('lang.transport.hotelWarningTitle'), {
          confirmButtonText: this.$t('lang.control.button.understood'),
          confirmButtonClass: 'apply-hotel-transport-info',
          cancelButtonText: this.$t('lang.control.button.correct'),
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.saveDataAndContinue('form')
        }).catch(() => {})
      } else {
        this.saveDataAndContinue('form')
      }
    },
    async saveDataAndContinue (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const confirmationNumbers = [this.confirmationNumber]
          const h = this.$createElement
          const selection = []

          selection.push(h('el-checkbox', {
            props: {
              key: this.confirmationNumber,
              value: true,
              disabled: true
            },
            style: {
              width: '100%'
            }
          }, 'Yo'))
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            this.partyApply.push({
              reservation: res,
              value: false
            })
          })
          this.transportData.reservationConsulted.partyConfirmations.forEach((res) => {
            const reservationCheckVal = this.partyApply.find((obj) => obj.reservation === res)
            selection.push(h('el-checkbox', {
              props: {
               key: res.confirmationNumber
             },
             domProps: { value: reservationCheckVal.value },
             on: { input: (value) => { reservationCheckVal.value = value } }
            }, `${res.travellerObj.firstName} ${res.travellerObj.lastName}`))
          })

          let canContinue = true
          if (parseInt(this.transportData.reservationConsulted.partyCode) === this.confirmationNumber && this.transportData.reservationConsulted.partyConfirmations.length > 0) {
            canContinue = false
            await this.$msgbox({
              title: this.$t('lang.applyTransportParty.message'),
              message: h('div', null, [
                h('p', null, this.$t('lang.applyTransportParty.description')),
                h('br', null, ''),
                selection
              ]),
              showCancelButton: true,
              confirmButtonText: 'Aplicar',
              confirmButtonClass: 'apply-transport-info',
              cancelButtonText: 'Cancelar',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  canContinue = true
                  this.partyApply.filter((p) => p.value === true).forEach(p => {
                    confirmationNumbers.push(p.reservation.confirmationNumber)
                  })
                  done()
                } else {
                  done()
                }
              }
            }).then(() => {}).catch(() => {})
          }
          if (canContinue) {
            const nextStep = this.type === 'ARRIVAL' ? 2 : 3
            this.loadingRef.showLoading();
            await this.modifyTravellerStepStatus({ token: this.$route.params.profileToken, travellerId: this.transportData.idTraveller, stepNumber: nextStep, status: 'OK', confirmationNumber: this.confirmationNumber, legNumber: this.legNumber })
            if (this.isLast) {
              this.changeStep(nextStep)
            } else {
              this.addIndex()
            }
            let transportType = ''
            // let cityCode = ''

            const transport = this.transportsInformation.find(t => t.locationCode === this.form.hotel && t.hotel.id === this.hotel.id && t.transportType === 'AEROPUERTO')
            if (transport) {
              transportType = transport.transportTypeOws
              // cityCode = transport.cityCode
            }
            if (this.form.hotel === 'otro') {
              // cityCode = 'OTRO'
            }
            const resp = await this.updateTransport({
              travellerId: this.transportData.idTraveller,
              confirmationNumbers: confirmationNumbers,
              transportId: this.form.hotel === 'OTRO' ? 'OTRO' : this.form.hotel,
              transportType: this.form.hotel === 'OTRO' ? 'OTRO' : transportType,
              carrierCode: this.form.hotel === 'OTRO' ? 'OTRO' : 'HTL',
              locationCode: this.form.location.id,
              otherHotel: this.form.hotel === 'OTRO' ? this.form.otherHotel : null,
              date: this.dateTransport,
              queryType: this.type,
              legNumber: this.legNumber,
              warning: false,
              destinationType: 'HOTEL'
            })
            this.loadingRef.hideLoading();
            if (resp === true) {
              this.$notify({
                title: this.$t('lang.saved.title'),
                type: 'success',
                customClass: 'step-transport-hotel-complete'
              })
            } else {
              this.$notify({
                title: this.$t('lang.errorSave.title'),
                message: this.$t('lang.errorSave.message'),
                type: 'error'
              })
            }
          }
        } else {
          return false
        }
      })
    },
    GoBack () {
      this.goBack()
    }
  },
  created () {
    let reservation = null
    if (this.legNumber === 1) {
      reservation = this.transportData.reservationConsulted
    } else {
      reservation = this.transportData.aditionalReservations.filter(r => r.legNumber === this.legNumber)[0]
    }
    if (this.type === 'ARRIVAL') {
      if (reservation.arrivalDestinationType === 'HOTEL') {
        console.log('arrival', reservation)
        this.form.location = this.hotelLocations.find(l => l.id === reservation.arrivalTransportLocation)
        this.$nextTick(() => {
          this.form.hotel = reservation.arrivalTransportId
        })
        this.form.date = reservation.arrivalTransportDatetime
        this.form.otherHotel = reservation.arrivalOtherHotel
      }
    } else {
      if (reservation.departureDestinationType === 'HOTEL') {
        console.log('departure', reservation.departureTransportLocation)
        this.form.location = this.hotelLocations.find(l => l.id === reservation.departureTransportLocation)
        this.$nextTick(() => {
          this.form.hotel = reservation.departureTransportId
        })
        this.form.date = reservation.departureTransportDatetime
        this.form.otherHotel = reservation.departureOtherHotel
      }
    }
  }
}
</script>
<style lang="scss">
#HotelForm {
  .input-label {
    font-size: 12px;
    margin: 16px 0;
  }
  .el-card__body {
    padding: 8px 30px;
    font-style: italic;
    color: #636363;
    border: 1px solid #303133;
  }
  .title {
    display: flex;
  }
  .message {
    line-height: 1;
  }
}
</style>
