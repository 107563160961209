<template lang="pug">
#DatePicker
  div(style="margin-right: 24px;")
    .input-label-picker {{ $t('lang.control.date.day') }}
    el-select(v-model="day", filterable, placeholder="Selecciona día", id="dayField", style="width: 70px" :disabled="disabled")
      el-option(v-for="(d, index) in daysOfMonth", v-bind:key="index", :label="d.option" :value="d.value" :id="'day-' + d.value")
  div(style="margin-right: 24px;")
    .input-label-picker {{ $t('lang.control.date.month') }}
    el-select(v-model="month", filterable, placeholder="Selecciona mes", id="monthField", style="width: 120px" :disabled="disabled")
      el-option(v-for="(m, index) in months", v-bind:key="index", :label="m.option" :value="m.value", :id="'month-' + m.value")
  div
    .input-label-picker {{ $t('lang.control.date.year') }}
    el-select(v-model="year", filterable, placeholder="Selecciona año", id="yearField", style="width: 90px" :disabled="disabled")
      el-option(v-for="(y, index) in years", v-bind:key="index", :label="y" :value="y", :id="'year-' + y")
</template>
<script>
export default {
  props: ['value', 'disabled'],
  data () {
    return {
      day: '',
      month: '',
      year: ''
    }
  },
  methods: {
    getDaysInMonth (year, month) {
      return new Date(year, month, 0).getDate()
    },
    getNumberString (number) {
      return number < 10 ? '0' + number : number.toString()
    },
    UpdateDate () {
      // const date = new Date(this.year, this.month - 1, this.day)
      if (this.year !== '' && this.month !== '' && this.day !== '') {
        const date = `${this.year}-${this.month}-${this.day}`
        this.$emit('input', date)
      } else {
        this.$emit('input', null)
      }
    },
    init () {
      if (this.value && this.value !== '') {
        const splitedBirthDate = this.value.split('-')
        this.day = splitedBirthDate[2]
        this.month = splitedBirthDate[1]
        this.year = splitedBirthDate[0]
        this.UpdateDate()
      }
    }
  },
  created () {
    this.init()
  },
  watch: {
    month () {
      if (this.getDaysInMonth(this.year, this.month) < this.day) {
        this.day = this.getDaysInMonth(this.year, this.month)
      }
      this.UpdateDate()
    },
    day () {
      this.UpdateDate()
    },
    year () {
      this.UpdateDate()
    },
    value () {
      this.init()
    }
  },
  computed: {
    daysOfMonth () {
      const days = []
      const totalDays = this.getDaysInMonth(this.year, this.month)
      for (let index = 1; index <= totalDays; index++) {
        days.push({ option: this.getNumberString(index), value: this.getNumberString(index) })
      }
      return days
    },
    months () {
      return [
        { option: this.$t('lang.control.date.january'), value: '01' },
        { option: this.$t('lang.control.date.february'), value: '02' },
        { option: this.$t('lang.control.date.march'), value: '03' },
        { option: this.$t('lang.control.date.april'), value: '04' },
        { option: this.$t('lang.control.date.may'), value: '05' },
        { option: this.$t('lang.control.date.june'), value: '06' },
        { option: this.$t('lang.control.date.july'), value: '07' },
        { option: this.$t('lang.control.date.august'), value: '08' },
        { option: this.$t('lang.control.date.september'), value: '09' },
        { option: this.$t('lang.control.date.october'), value: '10' },
        { option: this.$t('lang.control.date.november'), value: '11' },
        { option: this.$t('lang.control.date.december'), value: '12' }
      ]
    },
    years () {
      const years = []
      const currentYear = new Date().getFullYear()
      // eslint-disable-next-line
      for (let index = currentYear; index > 1900; index--) {
        years.push(index)
      }
      return years
    }
  }
}
</script>
<style lang="scss">
#DatePicker {
  display: flex;
  .input-label-picker {
    font-size: 12px;
    margin: 0px 0;
  }
}
</style>
