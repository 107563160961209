<template lang='pug'>
#app
  //- font-awesome-icon(:icon="['fas', 'home']")
  router-view
</template>
<script>
export default {
  created () {
    const storedConfirmationNumber = window.localStorage.getItem('confirmationNumber')
    const storedConfirmationNumberToken = window.localStorage.getItem('confirmationNumberToken')

    if (storedConfirmationNumber && storedConfirmationNumberToken) {
      this.$store.commit('SET_CONFIRMATION_NUMBER', { confirmationNumber: storedConfirmationNumber, confirmationNumberToken: storedConfirmationNumberToken })
    }
  }
}
</script>
<style lang="scss">
@import './style/global.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
h1, h2 {
  font-size: 25px;
}
.el-checkbox__input.is-checked+.el-checkbox__label, .el-radio__input.is-checked+.el-radio__label {
  color: #606266 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-radio__input.is-checked .el-radio__inner {
  background-color: #606266 !important;
  border-color: #606266 !important;
}
.el-slider__bar {
  background-color: #606266 !important;
}
.el-slider__button {
  border: 2px solid #606266 !important;
}
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}
.loading-box {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
