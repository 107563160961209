<template lang="pug">
#HeaderSteps
  Header
  #Header
    .steps-navigator
      .circle.black.big(style="margin-right: 16px; margin-top: 13px;", @click="previousStep", v-bind:class="{ 'disabled-circle': currentStep === 1 }")
        .el-icon-arrow-left
      .steps
        el-progress.progress-bar(:percentage="percentage", :show-text="false")
        .circle-group
          div(v-for="(step, index) in localSteps", v-bind:key="index" :id="'step-' + index")
            .circle(@click="changeStep(index)", v-if="step.status == 'NO_OK'", v-bind:class="{ current: index === currentStep}")
            .circle.blue.soft(@click="changeStep(index)", v-if="step.status == 'IN_PROGRESS'", v-bind:class="{ current: index === currentStep}")
              .warning-icon !
            .circle.blue(@click="changeStep(index)", v-if="step.status == 'OK'", v-bind:class="{ current: index === currentStep}")
              .el-icon-check
      .circle.black.big(style="margin-left: 16px; margin-top: 13px;" @click="nextStep", v-bind:class="{ 'disabled-circle': currentStep === 7 }")
        .el-icon-arrow-right
    div(style="display:flex; justify-content: center;")
      .title {{ title }}
      el-tooltip(class="item" effect="dark" v-if="toolTip && toolTip.length > 0" :content="toolTip" placement="top-start")
        .tooltip ?
</template>
<script>
import Header from '../Header.vue'
import { mapGetters } from 'vuex'
import { stepValues } from '@/components/Form/formSteps' // eslint-disable-line

export default {
  props: [
    'visibleSteps',
    'travelerSteps',
    'currentStep',
    'changeStep',
    'currentStepValue',
    'title',
  ],
  components: {
    Header,
  },
  data() {
    return {
      localSteps: [],
    }
  },
  computed: {
    ...mapGetters(['travellerProfile']),

    reservation() {
      return this.travellerProfile?.reservationConsulted
    },

    currentStepKey() {
      return Object.keys(stepValues).find(
        (k) => stepValues[k] === this.currentStepValue
      )
    },

    toolTip() {
      return this.$t('lang.stepsTooltips.' + this.currentStepKey)
    },

    percentage() {
      return Math.max(
        0,
        (this.currentStep * 100) / (this.visibleSteps.length - 1) - 0.01
      )
    },
  },
  created() {
    this.travelerSteps.forEach((element) => {
      if (element.step === 2 || element.step === 3) {
        // Buscamos los pasos relacionados a este
        const steps = this.travelerSteps.filter(
          (step) => step.step === element.step
        )
        const stepInProgress = this.travelerSteps.filter(
          (step) =>
            step.step === element.step &&
            (step.status === 'IN_PROGRESS' || step.status === 'NO_OK')
        )
        const stepExists = this.localSteps.filter(
          (step) => step.step === element.step
        )
        let status = 'IN_PROGRESS'
        if (stepExists.length === 0) {
          steps.forEach((st) => {
            if (stepInProgress.length === 0) {
              status = 'OK'
            }
          })
          this.localSteps.push({
            id: steps[0].id,
            status: status,
            step: element.step,
          })
        }
      } else {
        this.localSteps.push(element)
      }
    })
  },
  methods: {
    nextStep() {
      if (this.currentStep + 1 < this.visibleSteps.length) {
        this.changeStep(this.currentStep + 1)
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.changeStep(this.currentStep - 1)
      }
    },
  }
}
</script>
<style lang="scss">
#Header {
  font-weight: bold;
  // background-image: url('../../../public/profile/logo.jpg');
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  color: black;
  font-size: 19px;
  z-index: 1;
  padding-top: 16px;
  .steps-navigator {
    display: flex;
    justify-content: center;
  }
  .step-counter {
    margin-bottom: 8px;
    font-size: 12px;
    font-style: italic;
    color: #717170;
  }
  .steps {
    width: 270px;
    height: 40px;
  }
  .circle-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -14px;
    z-index: 1;
    position: relative;
  }
  .disabled-circle {
    opacity: 0.5;
  }
  .title,
  .tooltip {
    margin-top: 16px;
  }
  .tooltip {
    margin-left: 8px;
    border: 1px solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }
  .progress-bar {
    margin-top: 24px;
    z-index: 0;
  }
  .circle {
    width: 18px;
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    border: 0.5px solid #888888b8;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.blue {
      background: #303133;
      color: white;
      &.soft {
        background: #606266;
      }
    }
    &.black {
      background: #606266;
      color: white;
      &.soft {
        background: #606266;
      }
    }
    &.big {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.current {
      border: 1px solid #3f3f3fb8;
      width: 20px;
      height: 20px;
    }
  }
  .el-icon-check {
    font-weight: bold;
    font-size: 12px;
  }
}
.el-progress-bar__inner {
  background-color: #303133 !important;
}
#Header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffffad;
  z-index: -1;
}
</style>
