export const stepSaved = state => state.stepSaved
export const groups = state => state.groups
export const travellerProfile = state => state.travellerProfile
export const confirmationNumber = state => state.confirmationNumber
export const confirmationNumberToken = state => state.confirmationNumberToken
export const nationalities = state => state.nationalities
export const cities = state => state.cities
export const states = state => state.states
export const countries = state => state.countries
export const tagsConditions = state => state.tagsConditions
export const interests = state => state.interests
export const transportsInformation = state => state.transportsInformation
export const travellerLang = state => state.travellerLang
export const thanksMessage = state => state.thanksMessage
export const generalTerms = state => state.generalTerms
export const generalTermsCovid = state => state.generalTermsCovid
export const locations = state => state.locations
// WebCheckin
export const travellersCompanion = state => state.travellersCompanion
export const travellerProfileLink = state => state.travellerProfileLink
export const pdiFile = state => state.pdiFile
export const passportFile = state => state.passportFile
export const waiverFile = state => state.waiverFile
export const isSigned = state => state.isSigned
export const traveller = state => state.traveller
export const reservation = state => state.reservation
export const allReservations = state => state.allReservations
export const loadingDocs = state => state.loadingDocs
export const pdiFilePreview = state => state.pdiFilePreview
export const passportFilePreview = state => state.passportFilePreview
export const hasPdiFile = state => state.hasPdiFile

export const basicInformation = state => state.basicInformation
export const transportData = state => state.transportData
export const dietInformation = state => state.dietInformation

export const getDisplayMessage = state => (code, lang) => {
  const message = state.displayMessages.find(m => m.code === code)
  if (!message) return null
  lang = lang.charAt(0).toUpperCase() + lang.slice(1)
  return message?.[`text${lang}`]
}
