<template lang="pug">
#RegisterYoung
  .upload-label {{ $t('lang.checkin.signWaiver') }}
  h4 {{ $t('lang.checkin.teenAuth') }}
  .table-box
    table(border)
      tr(v-for="trv in localTravellersCompanion")
        td {{ trv.firstName }} {{ trv.lastName }}
        td 
          el-radio(v-model='trv.activitiesAllowed' :label='true') {{ $t('lang.checkin.yesResponse') }}
          el-radio(v-model='trv.activitiesAllowed' :label='false') {{ $t('lang.checkin.noResponse') }}
  .button-group(style="margin-top: 16px;")
    el-button#explora-btn.secondary(@click="goBack") {{ $t('lang.control.button.goBack') }}
    el-button#explora-btn.primary(@click="generateNewLink") {{ $t('lang.control.button.continue') }}
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      localTravellersCompanion: []
    }
  },
  computed: {
    ...mapGetters(['travellersCompanion'])
  },
  watch: {
    travellersCompanion() {
      this.localTravellersCompanion = [...this.travellersCompanion]
    }
  },
  methods: {
    goBack () {
      this.$emit('changeStep', 1)
    },
    async generateNewLink() {
      this.$store.commit('SET_TRAVELLERS_COMPANION', { travellersCompanion: this.localTravellersCompanion })
      await this.$emit('generateLink', this.localTravellersCompanion)
      this.$emit('changeStep', 3)
    }
  },
  created() {
    this.localTravellersCompanion = [...this.travellersCompanion]
  }
}
</script>

<style lang="scss">
#RegisterYoung {
  td {
    width: 50%;
    padding: 16px;
  }
  table {
    width: 100%;
    border-collapse:collapse;
    border:1px solid #3b3b3b;
  }
  .table-box {
    padding: 0 16px;
  }
}
</style>