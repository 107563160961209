const mutations = {
  'SET_GROUPS' (state, { groups }) {
    state.groups = []
    state.groups = groups
  },
  'SET_TRAVELLER_PROFILE' (state, { travellerProfile }) {
    state.travellerProfile = travellerProfile
  },
  'SET_SAVED_STEP' (state, { stepSaved }) {
    state.stepSaved = stepSaved
  },
  'SET_CONFIRMATION_NUMBER' (state, { confirmationNumber, confirmationNumberToken }) {
    state.confirmationNumber = null
    localStorage.setItem('confirmationNumber', null)
    state.confirmationNumberToken = null
    localStorage.setItem('confirmationNumberToken', null)

    state.confirmationNumber = confirmationNumber
    localStorage.setItem('confirmationNumber', confirmationNumber)
    state.confirmationNumberToken = confirmationNumberToken
    localStorage.setItem('confirmationNumberToken', confirmationNumberToken)
  },
  'SET_NATIONALITIES' (state, { nationalities }) {
    state.nationalities = nationalities
  },
  'SET_TERMS' (state, { generalTerms }) {
    state.generalTerms = generalTerms
  },
  'SET_TERMS_COVID' (state, { generalTermsCovid }) {
    state.generalTermsCovid = generalTermsCovid
  },
  'SET_CITIES' (state, { cities }) {
    state.cities = cities
  },
  'SET_STATES' (state, { states }) {
    state.states = states
  },
  'SET_COUNTRIES' (state, { countries }) {
    state.countries = countries
  },
  'SET_TAGS_CONDITIONS' (state, { tagsConditions }) {
    state.tagsConditions = tagsConditions
  },
  'SET_INTERESTS' (state, { interests }) {
    state.interests = interests
  },
  'SET_TRANSPORT' (state, { transportsInformation }) {
    state.transportsInformation = transportsInformation
  },
  'SET_THANKS_MESSAGE_TRUE' (state) {
    state.thanksMessage = true
  },
  'SET_LOCATIONS' (state, { locations }) {
    state.locations = locations
  },
  'SET_DISPLAY_MESSAGES' (state, { displayMessages }) {
    state.displayMessages = displayMessages
  },
  'SET_TRAVELLERS_COMPANION' (state, { travellersCompanion }) {
    state.travellersCompanion = travellersCompanion
  },
  'SET_TRAVELLER_PROFILE_LINK' (state, { travellerProfileLink }) {
    state.travellerProfileLink = travellerProfileLink
  },
  'SET_TRAVELLER_SIGNED' (state, { isSigned }) {
    state.isSigned = isSigned
  },
  'SET_TRAVELLER_PROFILE_FILES' (state, { pdiFile, passportFile, waiverFile, pdiFilePreview, passportFilePreview }) {
    state.pdiFile = pdiFile
    state.passportFile = passportFile
    state.waiverFile = waiverFile
    state.pdiFilePreview = pdiFilePreview
    state.passportFilePreview = passportFilePreview
  },
  'SET_TRAVELLER' (state, { traveller }) {
    state.traveller = traveller
  },
  'SET_RESERVATION' (state, { reservation }) {
    state.reservation = reservation
  },
  'SET_ALL_RESERVATIONS' (state, { allReservations }) {
    state.allReservations = allReservations
  },
  'SET_LOADING_DOCS' (state, { loadingDocs }) {
    state.loadingDocs = loadingDocs
  },
  'SET_HAS_PDI_FILE' (state, { hasPdiFile }) {
    state.hasPdiFile = hasPdiFile
  },
  'SET_BASIC_INFORMATION' (state, { basicInformation }) {
    state.basicInformation = basicInformation
  },
  'SET_TRANSPORT_DATA' (state, { transportData }) {
    state.transportData = transportData
  },
  'SET_DIET_INFORMATION' (state, { dietInformation }) {
    state.dietInformation = dietInformation
  }
}

export default mutations
