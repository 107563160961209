<template lang="pug">
#TravellersHeader
  .img-content
    img.img-logo(src="../../public/profile/logo.jpg")
    .lang-options
      font-awesome-icon(:icon="['fas', 'globe-americas']", size="2x", style="color: rgb(95 98 102);")
      el-select.select-lang(v-model="lang", v-on:change="change")
        el-option(v-for="item in options", :key="item.value", :label="item.label", :value="item.value")
  .separator(v-if="this.travellerProfile")
  el-collapse(v-if="this.travellerProfile")
    el-collapse-item
      template(slot='title')
        .bold {{ $t('lang.welcome.reservationInformation') }}
        i.el-icon-circle-plus(style="font-size: 18px; margin-left: 6px;")
      .info-traveller(v-for="reservation in reservations")
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.confirmationNumber') }}: {{ reservation.confirmationNumber }}
        .txt-val(style="text-align: left;") Hotel: {{ reservation.hotel.name }}
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.checkin') }}: {{ reservation.checkin | formatedDate }}
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.checkout') }}: {{ reservation.checkout | formatedDate }}
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.program') }}: {{ travellerType }}
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.roomType') }}: {{ reservation.roomTypeDesc }}
        .txt-val(style="text-align: left;") {{ $t('lang.welcome.bed') }}: {{ reservation.roomType2 }}
  .separator
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['traveller'],
  data () {
    return {
      options: [{
        value: 'es',
        label: 'Español'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'pt',
        label: 'Português'
      }
      ],
      lang: 'en'
    }
  },
  methods: {
    change () {
      localStorage.setItem('lang', this.lang)
      location.reload()
    }
  },
  computed: {
    ...mapGetters(['travellerProfile']),
    reservations () {
      const reservations = []
      reservations.push(this.travellerProfile.reservationConsulted)
      this.travellerProfile.aditionalReservations.forEach(res => {
        reservations.push(res)
      })

      return reservations
    },
    hotels () {
      const hotels = []
      hotels.push(this.travellerProfile.reservationConsulted.hotel.name)
      this.travellerProfile.aditionalReservations.forEach(res => {
        hotels.push(res.hotel.name)
      })

      return hotels.join(', ')
    },
    checkout () {
      let checkout = this.travellerProfile.reservationConsulted.checkout
      if (this.travellerProfile.aditionalReservations.length > 0) {
        checkout = this.travellerProfile.aditionalReservations[this.travellerProfile.aditionalReservations.length - 1].checkout
      }
      return checkout
    },
    travellerType () {
      const ttype = this.travellerProfile.reservationConsulted.travellerType.toLowerCase()
      return ttype.charAt(0).toUpperCase() + ttype.slice(1)
    }
  },
  created () {
    const selectedLang = localStorage.getItem('lang')
    if (selectedLang) {
      this.lang = selectedLang
    } else if (this.traveller.lang) {
      this.lang = this.traveller.lang.toLowerCase()
      localStorage.setItem('lang', this.lang)
    }
  }
}
</script>
<style lang="scss">
#TravellersHeader {
  text-align: left;
  .txt-val {
    margin: 0;
    width: 50%;
    font-size: 13px;
  }
  .info-traveller {
    display: flex;
    flex-wrap: wrap;
    font-weight: normal;
    padding: 8px 16px;
  }
  .img-content {
    padding: 4px 16px;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .img-logo {
    height: 65px;
    // height: 38px;
    width: auto;
    margin: 10px;
  }
  .separator {
    width: 100%;
    height: 15px;
    background-color: #f2f1ee;
  }
  .select-lang {
    width: 100px;
    margin-left: 8px;
  }
  .lang-options {
    display: flex;
    align-items: center;
  }
  .el-collapse-item__header {
    padding: 0 12px;
  }
  .bold {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
