<template lang="pug">
#BlockedMsg
  .blocked-msg(v-if="isTransport", v-html="$t('lang.blockedComponent.blockedMsgTransport')")
  .blocked-msg(v-else) {{ $t('lang.blockedComponent.blockedMsg') }}
  .btn
    el-button#explora-btn.primary(@click="exit") {{ $t('lang.blockedComponent.blockedBtn') }}
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isTransport: {
      default: false
    }
  },
  computed: {
    ...mapGetters(['confirmationNumberToken'])
  },
  methods: {
    exit () {
      this.$router.push(`/travellerProfile/${this.confirmationNumberToken}`)
    }
  }
}
</script>
<style lang="scss">
#BlockedMsg {
  text-align: left;
  padding: 22px;
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
}
</style>
