<template>
  <div class="custom-loading" v-if="show">
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  },
  methods: {
    showLoading() {
      this.show = true;
    },
    hideLoading() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.custom-loading {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999; /* Asegúrate de que esté en la parte superior */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-loading i {
  font-size: 24px;
  animation: spin 1s linear infinite; /* Añade animación de giro */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
