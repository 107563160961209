import Vue from 'vue'
import VueResource from 'vue-resource'
import settings from '@/../settings.js'

import {
  getTravellers,
  getTravellerProfile,
  getTravellerProfileLink,
  modifyStepStatus,
  getInformation, getNationalities,
  saveTravellerProfileData,
  getTagsConditions,
  saveTravellerConditions,
  saveTravellerFitnessConditions,
  getInterest,
  updateTransportRequirements,
  updateInterests,
  updateAboutUsAnswer,
  getHotelTransports,
  ReservationCancellTransport,
  getTerms,
  mutationUpdateMapiPreferredDate,
  mutationUpdateTravellerDataMapi,
  getLocations as queryGetLocations,
  queryGetDisplayMessages,
  getTravellersTeenagersCompanion,
  getBasicInformation,
  getTransportData,
  getDietInformation,
} from './apolloMutations.js'

Vue.use(VueResource)

export const signDocument = ({_}, { data }) => {
  const url = `${process.env.VUE_APP_GRAPHQL_BASE}/operation/get_waiver`;
  // return Vue.http.post(url, data, options)
  return Vue.http.post(url, data)
    .then(response => {
      return { url: response.data.url, waiverHtml: response.data.html }
    })
    .catch(error => {
      console.error('Error al cargar el PDF:', error);
    });
};

export const getTraveller = async ({ commit }, { token }) => {
  await getTravellers({ token: token }).then(response => {
    commit('SET_GROUPS', { groups: response.reservationTravellersProfile })
    commit('SET_CONFIRMATION_NUMBER', { confirmationNumber: response.reservationTravellersProfile[0].reservations[0].confirmationNumber, confirmationNumberToken: token })
    commit('SET_DISPLAY_MESSAGES', { displayMessages: response.displayMessages })
    return response.reservationTravellersProfile
  })
}

export const getProfile = async ({ commit }, { token, confirmationNumber }) => {
  await getTravellerProfile({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRAVELLER_PROFILE', { travellerProfile: response.publicTravellerProfile })
    return response.publicTravellerProfile
  })
}

export const getTransport = async ({ commit }, { token, confirmationNumber }) => {
  await getTransportData({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRANSPORT_DATA', { transportData: response.publicTravellerProfile })
    return response.publicTravellerProfile
  })
}

export const getDiet = async ({ commit }, { token, confirmationNumber }) => {
  await getDietInformation({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_DIET_INFORMATION', { dietInformation: response.publicTravellerProfile })
    return response.publicTravellerProfile
  })
}

export const getBasicData = async ({ commit, dispatch }, { token, confirmationNumber }) => {
  await getBasicInformation({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_BASIC_INFORMATION', { basicInformation: response.publicTravellerProfile })
    return response.publicTravellerProfile
  })
}

export const getProfileLink = async ({ commit, dispatch }, { token, confirmationNumber }) => {
  commit('SET_LOADING_DOCS', { loadingDocs: true })
  await getTravellerProfileLink({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    const reservation = response.publicTravellerProfile.reservationConsulted
    let reservations = reservation.aditionalReservations
    delete reservation.aditionalReservations
    reservations.unshift(reservation)
    
    // Necesito reemplazar el atributo idReservation por id
    reservations = reservations.map((r) => {
      r.id = r.idReservation
      delete r.idReservation
      return r
    })
    dispatch('setReservationAttributes', { reservation })
    commit('SET_ALL_RESERVATIONS', { allReservations: reservations })
    return response.publicTravellerProfile
  })
}

export const setReservationAttributes = async ({ commit }, { reservation }) => {
  commit('SET_TRAVELLER_PROFILE_LINK', { travellerProfileLink: reservation.linkTravellerProfile })
  commit('SET_TRAVELLER_PROFILE_FILES', {
    pdiFile: reservation.pdiFileLink,
    passportFile: reservation.passportFileLink,
    waiverFile: reservation.waiverFile,
    pdiFilePreview: reservation.pdiFilePreviewLink,
    passportFilePreview: reservation.passportFilePreviewLink,
  })

  commit('SET_TRAVELLER_SIGNED', { isSigned: reservation.isSigned })
  commit('SET_TRAVELLER', { traveller: reservation.travellerObj })
  commit('SET_RESERVATION', { reservation: reservation })
  commit('SET_LOADING_DOCS', { loadingDocs: false })
}

export const getTravellersTeenagers = async ({ commit }, { token, confirmationNumber }) => {
  await getTravellersTeenagersCompanion({ token: token, confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRAVELLERS_COMPANION', { travellersCompanion: response.travellersTeenagersCompanion.map((trv) => ({
      ...trv,
      activitiesAllowed: false,
    }))})
    return response.publicTravellerProfile
  })
}

export const getFormInformation = async ({ commit }) => {
  // localStorage.getItem('lang')
  await getNationalities({ lang: localStorage.getItem('lang') }).then(response => {
    commit('SET_NATIONALITIES', { nationalities: response.nationalities })
  })
  await getInformation({ informationType: 'COUNTRYCODES', countryCode: 'null' }).then(response => {
    commit('SET_COUNTRIES', { countries: response.information })
  })
}

export const getStates = async ({ commit }, { countryCode }) => {
  await getInformation({ informationType: 'STATE', countryCode: countryCode }).then(response => {
    commit('SET_STATES', { states: response.information })
  })
}

export const getGeneralTerms = async ({ commit }, { termsType }) => {
  await getTerms({ termsType: termsType }).then(response => {
    if (termsType === 'COVID') {
      commit('SET_TERMS_COVID', { generalTermsCovid: response })
    } else {
      commit('SET_TERMS', { generalTerms: response })
    }
  })
}

export const modifyTravellerStepStatus = async ({ commit }, { travellerId, stepNumber, status, confirmationNumber, legNumber = 1 }) => {
  await modifyStepStatus({ travellerId: travellerId, stepNumber: stepNumber, status: status, confirmationNumber: confirmationNumber, legNumber: legNumber })
}

export const saveBasicInformationData = async ({ commit }, {
  id, dni, name, lastName, gender, birthdate, nationality,
  country, state, city, zipCode, address, email, nameEmergency, emailEmergency, phoneNumber, phoneNumberEmergency,
  operaIdEmail, operaIdAddress, operaIdPhoneNumber,
  operaIdPhoneNumberEmergency, operaIdEmailEmergency, confirmationNumber, babySeat, saveAlsoTo,
}) => {
  const respons = await saveTravellerProfileData({
    id: id,
    dni: dni,
    name: name,
    lastName: lastName,
    gender: gender,
    birthdate: birthdate,
    nationality: nationality,
    country: country,
    state: state,
    city: city,
    zipCode: zipCode,
    address: address,
    email: email,
    nameEmergency: nameEmergency,
    emailEmergency: emailEmergency,
    phoneNumber: phoneNumber,
    phoneNumberEmergency: phoneNumberEmergency,
    operaIdEmail: operaIdEmail,
    operaIdAddress: operaIdAddress,
    operaIdPhoneNumber: operaIdPhoneNumber,
    operaIdPhoneNumberEmergency: operaIdPhoneNumberEmergency,
    operaIdEmailEmergency: operaIdEmailEmergency,
    confirmationNumber: confirmationNumber,
    babySeat: babySeat,
    saveAlsoTo,
  }).then(response => {
    if (!response.UpdateTravellerProfile.result) {
      return response.UpdateTravellerProfile.result
    }
    return response.UpdateTravellerProfile.result
  }).catch(erro => {
    return erro
  })

  return respons
}

export const saveTravellerRequirements = async ({ commit }, {
  travellerId,
  hasFoodReq,
  hasMedicalReq,
  foodRequirements,
  medicalConditions,
  foodAllergiesTags,
  medicalDiseaseTags,
  medicalInjuryTags,
  medicalSurgeryTags,
  medicalAllergiesTags,
  confirmationNumber,
  foodRestrictions,
  otherMedicalConditions
}) => {
  commit('SET_SAVED_STEP', { stepSaved: false })
  const response = await saveTravellerConditions({
    travellerId: travellerId,
    hasFoodReq: hasFoodReq,
    hasMedicalReq: hasMedicalReq,
    foodRequirements: foodRequirements,
    medicalConditions: medicalConditions,
    foodAllergiesTags: foodAllergiesTags,
    medicalDiseaseTags: medicalDiseaseTags,
    medicalInjuryTags: medicalInjuryTags,
    medicalSurgeryTags: medicalSurgeryTags,
    medicalAllergiesTags: medicalAllergiesTags,
    confirmationNumber: confirmationNumber,
    foodRestrictions: foodRestrictions,
    otherMedicalConditions: otherMedicalConditions
  }).then(resp => {
    if (!resp.UpdateTravellerConditions.result) {
      console.log('Algo salió mal')
    }
    return resp.UpdateTravellerConditions.result
  }).catch(error => {
    return error
  })
  commit('SET_SAVED_STEP', { stepSaved: true })

  return response
}

export const getTags = async ({ commit }) => {
  await getTagsConditions().then(response => {
    commit('SET_TAGS_CONDITIONS', { tagsConditions: response.tagsConditions })
  })
}

export const UpdatePhysicalConditions = async ({ commit }, { travellerId, confirmationNumber, physicalState, fitnessIntensity }) => {
  const resp = await saveTravellerFitnessConditions({ travellerId: travellerId, confirmationNumber: confirmationNumber, physicalState: physicalState, fitnessIntensity: fitnessIntensity }).then(response => {
    commit('SET_TAGS_CONDITIONS', { tagsConditions: response.tagsConditions })
    if (!response.UpdatePhysicalConditions.result) {
      console.log('Algo salió mal')
    }
    return response.UpdatePhysicalConditions.result
  }).catch(error => {
    return error
  })

  return resp
}

export const getInterests = async ({ commit }, { confirmationNumber }) => {
  await getInterest({ confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_INTERESTS', { interests: response.travellerInterests })
  })
}

export const getTransportInformation = async ({ commit }, { confirmationNumber }) => {
  await getHotelTransports({ confirmationNumber: confirmationNumber }).then(response => {
    commit('SET_TRANSPORT', { transportsInformation: response.hotelTransportInformation })
  })
}

export const getLocations = async ({ commit }) => {
  await queryGetLocations().then(response => {
    commit('SET_LOCATIONS', { locations: response.location })
  })
}

export const updateTravellerInterests = async ({ commit }, { travellerId, confirmationNumber, travellerInterests, commentary }) => {
  const resp = await updateInterests({ travellerId: travellerId, confirmationNumber: confirmationNumber, travellerInterests: travellerInterests, commentary: commentary }).then(response => {
    return response.UpdateTravellerInterests.result
  }).catch(error => {
    return error
  })
  return resp
}

export const CancellTransport = async ({ commit }, { travellerId, confirmationNumbers, legNumber, queryType, checkin, howArrive, arrivesToLunch }) => {
  const resp = await ReservationCancellTransport({ travellerId: travellerId, confirmationNumbers: confirmationNumbers, legNumber: legNumber, queryType: queryType, checkin: checkin, howArrive: howArrive, arrivesToLunch: arrivesToLunch }).then(response => {
    return response.CancellTransport.result
  }).catch(error => {
    return error
  })

  return resp
}

export const updateTransport = async ({ commit }, { travellerId, confirmationNumbers, transportId, transportType, carrierCode, locationCode, otherHotel, date, queryType, legNumber, warning, destinationType, shuttleTime }) => {
  const resp = await updateTransportRequirements({
    travellerId: travellerId,
    confirmationNumbers: confirmationNumbers,
    transportId: transportId,
    transportType: transportType,
    carrierCode: carrierCode,
    locationCode: locationCode,
    otherHotel: otherHotel,
    date: date,
    queryType: queryType,
    legNumber: legNumber,
    warning: warning,
    destinationType: destinationType,
    shuttleTime: shuttleTime,
  }).then(response => {
    return response.ModifyTransportRequirements.result
  }).catch(error => {
    return error
  })

  return resp
}

export const updateTravellerAboutUsAnswer = async ({ commit }, { travellerId, confirmationNumber, aboutUsValue, importantFactorValue }) => {
  await updateAboutUsAnswer({ travellerId, confirmationNumber, aboutUsValue, importantFactorValue }).then(response => {
    return response
  })
}

export const AddOrUpdateAttachment = ({ commit }, data) => {
  const url = `${process.env.VUE_APP_GRAPHQL_BASE}/operation/reservation_attachment`
  return Vue.http.post(url, data).then(
    response => response,
    error => error
  )
}

export const updateMapiPreferredDate = async (
  { commit },
  { mapiPreferredDate, confirmationNumber, legNumber }
) => {
  const res = await mutationUpdateMapiPreferredDate({
    mapiPreferredDate: mapiPreferredDate,
    confirmationNumber: confirmationNumber,
    legNumber: legNumber,
  })
  return res.UpdateMapiPreferredDate.result
}

export const updateTravellerDataMapi = async (
  { commit },
  {
    confirmationNumber, id, dni, name,
    lastName, birthdate, nationality,
  }
) => {
  const res = await mutationUpdateTravellerDataMapi({
    confirmationNumber: confirmationNumber,
    id: id,
    dni: dni,
    name: name,
    lastName: lastName,
    birthdate: birthdate,
    nationality: nationality,
  })
  return res.UpdateTravellerDataMapi.result
}

export const getDisplayMessages = async(_, { codes }) => {
  return await queryGetDisplayMessages(codes)
}

export const uploadTravellerAvatar = async ({ commit }, form) => {
  const url = `${settings.graphqlBase}/traveller/upload_traveller_avatar`
  return Vue.http.patch(url, form).then(
    response => response,
    error => error
  )
}
