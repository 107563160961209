<template lang="pug">
#Basic(v-if="basicInformation")
  el-form.form(ref="form", :model="form", :rules="rules")
    //- Información básica
    .input-label {{ $t('lang.basicInformation.name') }}
     .red *
    el-form-item(prop="name")
      el-input(v-model="form.name", ref="name", id="nameField" v-on:keypress.native="isLetter($event)", :disabled="isBlocked")

    .input-label {{ $t('lang.basicInformation.lastName') }}
      .red *
    el-form-item(prop="lastName")
      el-input(v-model="form.lastName", ref="lastName", id="lastNameField" v-on:keypress.native="isLetter($event)" :disabled="isBlocked")

    .input-label {{ $t('lang.basicInformation.gender') }}
      .red *
    el-form-item(prop="gender")
      el-select(v-model="form.gender", placeholder="Selecciona género", id="genderField", style="width: 100%;",  ref="gender" :disabled="isBlocked", @touchstart="onTouchStart")
        el-option(:label="$t('lang.basicInformation.male')" value="MALE" id="maleOption")
        el-option(:label="$t('lang.basicInformation.female')" value="FEMALE" id="femaleOption")
        el-option(:label="$t('lang.basicInformation.unknown')" value="UNKNOWN" id="unknownOption")

    .input-label {{ $t('lang.basicInformation.birthDay') }}
      .red *
    el-form-item(prop="birthdate")
      DatePicker(v-model="form.birthdate", ref="birthdate" :disabled="isBlocked")
      span(v-if="this.showBabySeat" style="width: 100%;")
        .d-flex(style="height: 50px;")
          .input-label {{ $t('lang.basicInformation.babySeat') }}
            .red *
          //- el-tooltip(class="item" effect="dark" content="Info silla bebé" placement="top-start")
          //-   .tooltip ?
        el-select(v-model="form.babySeat" style="width:  100%;", :placeholder="$t('lang.basicInformation.babyseatPH')" :disabled="isBlocked")
          el-option(v-for="option in $t('lang.basicInformation.babySeatOptions')" :label="option.label", :value="option.value")

    .input-label {{ $t('lang.basicInformation.nationality') }}
      .red *
    el-form-item(prop="nationality")
      el-select(v-model="form.nationality", filterable, id="nationalityField",:placeholder="$t('lang.basicInformation.nationalityPlaceholder')", style="width: 100%;" :disabled="isBlocked" append-to-body)
        el-option(v-for="(n, index) in nationalities", v-bind:key="index", :label="n.description", :value="n.value", :id="'nationality-' + n.value")
    .special-form
      div(style="width: 45%")
        .input-label {{ $t('lang.basicInformation.documentType') }}
        el-input(v-model="form.docType", disabled)
      div(style="width: 45%")
        .input-label {{ $t('lang.basicInformation.document') }}
          .red *
        el-form-item(prop="document")
          el-input(v-model="form.document", ref="document", id="dniField" :disabled="isBlocked")

    //- Información de contacto
    h2 {{ $t('lang.basicInformation.contactTitle') }}
    .input-label {{ $t('lang.basicInformation.email') }}
      .red *
    el-form-item(prop="email")
      el-input(v-model="form.email" type="email" ref="email" id="emailField" :disabled="isBlocked")

    .input-label {{ $t('lang.basicInformation.phoneNumber') }}
      .red *
    el-form-item(prop="phoneNumber")
      phone-input(v-model="inpNum", v-if="ready", :max_length="11", reference="phoneNumber" id="phoneField" :disabled="isBlocked")


    //- Direccion
    h2 {{ $t('lang.basicInformation.addressTitle') }}
    .input-label {{ $t('lang.basicInformation.country') }}
      .red *
    el-form-item(prop="country")
      el-select(v-model="form.country", filterable, :placeholder="$t('lang.basicInformation.country')", style="width: 100%;" id="countryField" :disabled="isBlocked", popper-append-to-body)
        el-option(v-for="(c, index) in countries", v-bind:key="index", :label="c.description" :value="c.value", :id="'country-' + c.value")

    div(v-if="states.length > 0")
      .input-label {{ $t('lang.basicInformation.state') }}
        .red *
      el-form-item(prop="state")
        el-select(v-model="form.state", filterable, :placeholder="$t('lang.basicInformation.state')", style="width: 100%;", id="stateField" :disabled="isBlocked")
          el-option(v-for="(s, index) in states", v-bind:key="index", :label="s.name" :value="s.state_code", :id="'state-' + s.state_code")

    .input-label {{ $t('lang.basicInformation.city') }}
      .red *
    el-form-item(prop="city")
      el-select(v-model="form.city", filterable, ref="city", style="width: 100%;", id="cityField" :disabled="isBlocked")
        el-option(v-for="(c, index) in cities", v-bind:key="index", :label="c.name" :value="c.name" :id="'city-' + c.name")

    .input-label {{ $t('lang.basicInformation.address') }}
      .red *
    el-form-item(prop="address")
      el-input(v-model="form.address", ref="address", id="addressField" :disabled="isBlocked")

    //- Contacto de emergencia
    h2 {{ $t('lang.basicInformation.emergencyTitle') }}
    .input-label {{ $t('lang.basicInformation.nameEmergency') }}
    el-form-item(prop="nameEmergency")
      el-input(v-model="form.nameEmergency", ref="nameEmergency" :disabled="isBlocked")

    .input-label {{ $t('lang.basicInformation.email') }}
    el-form-item(prop="emailEmergency")
      el-input(v-model="form.emailEmergency" ref="emailEmergency" :disabled="isBlocked" type="email")

    .input-label {{ $t('lang.basicInformation.phoneNumber') }}
    el-form-item(prop="phoneNumberEmergency")
      phone-input(v-model="inpNumEmergency", v-if="ready" :max_length="11", reference="phoneNumberEmergency" id="phoneEmergencyField" :disabled="isBlocked")

    h2(v-if='basicInformation.reservationConsulted.exploraConnectId && basicInformation.reservationConsulted.packagesCodes.filter(pkc => ["3NBMCH", "4NBMCH", "3NCHBM", "4NCHBM"].includes(pkc)).length > 0') {{ $t('lang.basicInformation.termsTitle') }}
    el-collapse(v-model="activeName" accordion v-if="basicInformation.reservationConsulted.exploraConnectId && basicInformation.reservationConsulted.packagesCodes.filter(pkc => ['3NBMCH', '4NBMCH', '3NCHBM', '4NCHBM'].includes(pkc)).length > 0")
      el-collapse-item(:title="$t('lang.basicInformation.TermsAndConditionsConnectsParraf.title')" name="2", v-if="basicInformation.reservationConsulted.exploraConnectId")
        .terms
          div(v-html="form.termsConnects")
      el-collapse-item(:title="$t('lang.basicInformation.TermsAndConditionsTsrParraf.title')" name="3", v-if="basicInformation.reservationConsulted.packagesCodes.filter(pkc => ['3NBMCH', '4NBMCH', '3NCHBM', '4NCHBM'].includes(pkc)).length > 0")
        .terms
          div(v-html="form.termsTsr")
    br
    el-checkbox(type="checkbox" id="termsConnect" value="false" v-model="form.termsConnectsChecked" ref="termsConnectsChecked" v-if="basicInformation.reservationConsulted.exploraConnectId") {{ $t('lang.basicInformation.termsAndConditionsConnects') }}
    el-checkbox(type="checkbox" id="termsTsr" value="false" v-model="form.termsCheckedTsr" ref="termsCheckedTsr" v-if="basicInformation.reservationConsulted.packagesCodes.filter(pkc => ['3NBMCH', '4NBMCH', '3NCHBM', '4NCHBM'].includes(pkc)).length > 0") {{ $t('lang.basicInformation.termsAndConditionsTsr') }}
    // el-checkbox(type="checkbox" id="termsEmail" value="false" v-model="form.termsCheckedMail" ref="termsCheckedTsr") {{ $t('lang.basicInformation.termsAndConditionsMail') }}
    blocked-msg(v-if="isBlocked")
    .button-group(v-else)
      el-button#explora-btn.secondary(@click="saveData('form')") {{ $t('lang.control.button.goBack') }}
      el-button#explora-btn.primary(@click="saveDataAndContinue('form')", class="saveBtn") {{ $t('lang.control.button.continue') }}

  SaveAlsoToModal(
    v-model="showSaveAlsoToModal"
    :partyLeader="saveAlsoDialogPartyLeader"
    :travellers="saveAlsoDialogTravellers"
    @submit="submitSaveAlsoTo"
  )
.loading-box(v-else)
  div(v-loading="true")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { Loading } from 'element-ui'
import DatePicker from '../Utils/DatePicker'
import PhoneInput from '../Utils/PhoneInput'
import { termsAndConditions } from '../../mocks/terms'
import { stepValues } from './formSteps'
import BlockedMsg from './BlockedMsg.vue'
import locations from '../../../plugins/locations.json'
import moment from 'moment'
import SaveAlsoToModal from './SaveAlsoToModal.vue'

export default {
  props: ['changeStep', 'isBlocked', 'loadingRef', 'destinationsBlocked'],
  components: {
    DatePicker,
    BlockedMsg,
    SaveAlsoToModal,
    PhoneInput
  },
  data() {
    return {
      activeName: '1',
      ready: false,
      terms: termsAndConditions,
      states: [],
      cities: [],
      showBabySeat: false,
      componentKey: 100,
      showSaveAlsoToModal: false,
      emimtAfterSave: '',
      form: {
        travellerId: null,
        name: '',
        lastName: '',
        gender: '',
        birthdate: null,
        nationality: '',
        country: '',
        state: '',
        textState: '',
        city: '',
        zipCode: '',
        address: '',
        docType: this.$t('lang.basicInformation.dni'),
        document: '',
        email: '',
        prefixPhone: '',
        phoneNumber: '',
        nameEmergency: '',
        emailEmergency: '',
        prefixPhoneEmergency: '',
        phoneNumberEmergency: '',
        operaIdAddress: '',
        operaIdEmail: '',
        operaIdEmailEmergency: '',
        operaIdPhoneNumber: '',
        operaIdphoneNumberEmergency: '',
        termsGeneral: '',
        termsConnects: '',
        termsTsr: '',
        termsChecked: null,
        babySeat: null,
        termsConnectsChecked: null,
        termsCheckedTsr: null,
        termsCheckedMail: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.name'),
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.lastName'),
            trigger: 'blur',
          },
        ],
        document: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.document'),
            trigger: 'blur',
          },
        ],
        gender: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.gender'),
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.birthdate'),
            trigger: 'blur',
          },
        ],
        nationality: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.nationality'),
            trigger: 'blur',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.country'),
            trigger: 'blur',
          },
        ],
        state: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.state'),
            trigger: 'blur',
          },
        ],
        textState: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.textState'),
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.address'),
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.email'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('lang.basicInformation.errors.emailFormat'),
            trigger: 'blur',
          },
        ],
        emailEmergency: [
          {
            type: 'email',
            message: this.$t('lang.basicInformation.errors.emailFormat'),
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.phoneNumber'),
            trigger: 'blur',
          },
        ],
        termsConnectsChecked: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.termsChecked'),
            trigger: 'blur',
          },
        ],
        termsCheckedTsr: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.termsChecked'),
            trigger: 'blur',
          },
        ],
        termsCheckedMail: [
          {
            required: true,
            message: this.$t('lang.basicInformation.errors.termsChecked'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    ...mapActions([
      'saveBasicInformationData',
      'modifyTravellerStepStatus',
      'getGeneralTerms',
      'getBasicData'
    ]),
    onTouchStart(event) {
      event.preventDefault();
      event.target.click();
    },
    validateFocus() {
      if (this.$refs.name.validateState === 'error') {
        this.$refs.name.focus()
        return false
      }
      if (this.$refs.lastName.validateState === 'error') {
        this.$refs.lastName.focus()
        return false
      }
      if (this.$refs.document.validateState === 'error') {
        this.$refs.document.focus()
        return false
      }
      if (this.$refs.gender.validateState === 'error') {
        this.$refs.gender.focus()
        return false
      }
      if (this.$refs.birthdate.validateState === 'error') {
        this.$refs.birthdate.focus()
        return false
      }
      if (this.$refs.address.validateState === 'error') {
        this.$refs.address.focus()
        return false
      }
      if (this.$refs.email.validateState === 'error') {
        this.$refs.email.focus()
        return false
      }
      if (this.$refs.phoneNumber.validateState === 'error') {
        this.$refs.phoneNumber.focus()
        return false
      }
    },
    setTerms() {
      this.form.termsConnects =
        this.basicInformation.reservationConsulted.hotel.termsAndConditionsConnect
      this.form.termsTsr =
        this.basicInformation.reservationConsulted.hotel.termsAndConditionsTsr
    },
    updatePrefix(val, data) {
      this.form[data] = val
    },
    async saveData(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.confirmAndSave()
          this.emimtAfterSave = 'previousStep'
        } else {
          this.validateFocus()
          return false
        }
      })
    },
    validate(form) {
      this.$refs[form].validate(() => {})
    },
    isLetter(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\s]*$/.test(char)) return true
      else e.preventDefault()
    },
    async saveDataAndContinue(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          if (
            this.form.termsConnectsChecked === false ||
            this.form.termsCheckedTsr === false
          ) {
            this.$notify.error({
              title: 'Error',
              message: this.$t('lang.basicInformation.termsError'),
              customClass: 'step-basic-terms-error',
            })
          } else if (this.form.birthdate === null) {
            this.$notify.error({
              title: 'Error',
              message: this.$t('lang.basicInformation.errors.birthdate'),
            })
          } else {
            if (this.showBabySeat === true && this.form.babySeat === null) {
              this.$notify.error({
                title: 'Error',
                message: this.$t('lang.basicInformation.errors.babySeat'),
              })
            } else {
              this.confirmAndSave()
              this.emimtAfterSave = 'nextStep'
            }
          }
        } else {
          this.validateFocus()
          return false
        }
      })
    },

    async confirmAndSave() {
      if (this.isPartyLeader) {
        this.showSaveAlsoToModal = true
        return
      }
      this.saveBasicInformation()
    },

    submitSaveAlsoTo(selectedIds) {
      this.saveBasicInformation(selectedIds)
    },

    async saveBasicInformation(saveAlsoTo = []) {
      this.loadingRef.showLoading();
      if (this.emimtAfterSave === 'nextStep') {
        const totalReservations = [...this.travellerProfile.aditionalReservations, this.travellerProfile.reservationConsulted]
        if (this.destinationsBlocked.length === totalReservations.length) {
          console.log('HERE')
          console.log(this.destinationsBlocked.length)
          console.log(totalReservations.length)
          this.changeStep(stepValues.REQUIREMENTS - 1)
        } else {
          console.log('HERE 2')
          this.$emit(this.emimtAfterSave)
        }
      } else {
        console.log('HERE 3')
        this.$emit(this.emimtAfterSave)
      }
      this.modifyTravellerStepStatus({
        token: this.$route.params.profileToken,
        travellerId: this.form.travellerId,
        stepNumber: stepValues.BASIC_INFO,
        status: 'OK',
        confirmationNumber: this.$route.params.confirmationNumber,
      })
      const resp = await this.saveBasicInformationData({
        id: this.form.travellerId,
        dni: this.form.document,
        name: this.form.name,
        lastName: this.form.lastName,
        gender: this.form.gender,
        birthdate: this.form.birthdate,
        nationality: this.form.nationality,
        country: this.form.country,
        state:
          this.form.state &&
          this.form.state !== 'NONE' &&
          this.form.state !== ''
            ? this.form.state
            : this.form.textState,
        city: this.form.city,
        zipCode: this.form.zipCode,
        address: this.form.address,
        email: this.form.email,
        nameEmergency: this.form.nameEmergency,
        emailEmergency: this.form.emailEmergency,
        phoneNumber: this.form.prefixPhone + this.form.phoneNumber,
        phoneNumberEmergency: this.form.prefixPhoneEmergency + this.form.phoneNumberEmergency,
        operaIdEmail: this.form.operaIdEmail,
        operaIdAddress: this.form.operaIdAddress,
        operaIdPhoneNumber: this.form.operaIdPhoneNumber,
        operaIdPhoneNumberEmergency: this.form.operaIdphoneNumberEmergency,
        operaIdEmailEmergency: this.form.operaIdEmailEmergency,
        confirmationNumber: this.$route.params.confirmationNumber,
        babySeat: this.form.babySeat,
        saveAlsoTo,
      })
      this.loadingRef.hideLoading();
      if (resp === true) {
        this.$notify({
          title: this.$t('lang.saved.title'),
          type: 'success',
          customClass: 'step-basic-complete',
        })
      } else {
        this.$notify({
          title: this.$t('lang.errorSave.title'),
          message: `${this.$t('lang.errorSave.message')}`,
          type: 'error',
        })
      }
    },
  },
  computed: {
    ...mapGetters([
      'nationalities',
      'countries',
      'basicInformation',
      'confirmationNumberToken',
      'confirmationNumber',
      'generalTerms',
      'travellerProfile',
    ]),
    thisCountry() {
      return this.form.country
    },
    inpNum: {
      get: function () {
        return this.form.phoneNumber
      },
      set: function (newValue) {
        this.form.phoneNumber = newValue.replace(/[^\d]/g, '')
      },
    },
    inpNumEmergency: {
      get: function () {
        return this.form.phoneNumberEmergency
      },
      set: function (newValue) {
        this.form.phoneNumberEmergency = newValue.replace(/[^\d]/g, '')
      },
    },
    isPartyLeader() {
      const { partyCode, confirmationNumber, partyConfirmations } =
        this.basicInformation.reservationConsulted
      return partyCode == confirmationNumber && partyConfirmations.length > 0
    },
    saveAlsoDialogPartyLeader() {
      if (!this.isPartyLeader) return { id: 0, name: '' }
      return {
        id: this.basicInformation.idTraveller,
        name: `${this.basicInformation.firstName} ${this.basicInformation.lastName}`,
      }
    },
    saveAlsoDialogTravellers() {
      if (!this.isPartyLeader) return []
      return this.basicInformation.reservationConsulted.partyConfirmations.map(
        (res) => ({
          id: res.travellerObj.id,
          name: `${res.travellerObj.firstName} ${res.travellerObj.lastName}`,
        })
      )
    },
  },
  async created() {
    await this.getBasicData({
      token: this.$route.params.profileToken,
      confirmationNumber: this.$route.params.confirmationNumber,
    })
    this.form.travellerId = this.basicInformation.idTraveller
    const loading = Loading.service()
    this.form.name = this.basicInformation.firstName
    this.form.lastName = this.basicInformation.lastName
    if (this.basicInformation.gender === 'M') {
      this.form.gender = 'MALE'
    } else if (this.basicInformation.gender === 'F') {
      this.form.gender = 'FEMALE'
    } else {
      this.form.gender = 'UNKNOWN'
    }
    this.form.birthdate =
      this.basicInformation.birthday === null
        ? ''
        : this.basicInformation.birthday
    this.form.nationality = this.basicInformation.nationality
    this.form.country = this.basicInformation.address
      ? this.basicInformation.address.country
      : ''
    this.form.state = this.basicInformation.address
      ? this.basicInformation.address.region
      : ''
    await this.getGeneralTerms({ termsType: 'GENERAL' })
    loading.close()
    this.form.operaIdAddress = this.basicInformation.address
      ? this.basicInformation.address.operaId
      : ''
    this.form.textState = this.basicInformation.address
      ? this.basicInformation.address.region
      : ''
    this.form.city = this.basicInformation.address
      ? this.basicInformation.address.city
      : ''
    this.form.zipCode = this.basicInformation.address
      ? this.basicInformation.address.zipCode
      : ''
    this.form.address = this.basicInformation.address
      ? this.basicInformation.address.address
      : ''
    this.setTerms()
    this.form.document = this.basicInformation.dni
    const email = this.basicInformation.contacts.find(
      (c) => c.contactRole === 'EMAIL' && c.contactType === 'EMAIL'
    )
    this.form.nameEmergency = this.basicInformation.nameEmergency
    const emailEmergency = this.basicInformation.contacts.find(
      (c) => c.contactRole === 'EMAIL' && c.contactType === 'EMRG_EMAIL'
    )
    const phoneNumber = this.basicInformation.contacts.find(
      (c) => c.contactRole === 'PHONE' && c.contactType === 'MOBILE'
    )
    const phoneNumberEmergency = this.basicInformation.contacts.find(
      (c) => c.contactRole === 'PHONE' && c.contactType === 'EMERGENCY'
    )
    this.form.email = email ? email.value : ''
    this.form.emailEmergency = emailEmergency ? emailEmergency.value : ''
    this.form.phoneNumber = phoneNumber ? phoneNumber.value : ''
    // this.form.phoneNumber = '56953937787'
    this.form.phoneNumberEmergency = phoneNumberEmergency
      ? phoneNumberEmergency.value
      : ''
    this.form.operaIdEmail = email ? email.operaId : 'None'
    this.form.operaIdEmailEmergency = emailEmergency
      ? emailEmergency.operaId
      : 'None'
    this.form.operaIdPhoneNumber = phoneNumber ? phoneNumber.operaId : 'None'
    this.form.operaIdphoneNumberEmergency = phoneNumberEmergency
      ? phoneNumberEmergency.operaId
      : 'None'

    this.form.termsConnectsChecked = this.basicInformation.reservationConsulted
      .termsConnectsAccepted
      ? true
      : this.basicInformation.reservationConsulted.exploraConnectId
      ? false
      : null
    this.form.termsCheckedTsr = this.basicInformation.reservationConsulted
      .termsTsrAccepted
      ? true
      : this.basicInformation.reservationConsulted.packagesCodes.filter((pkc) =>
          ['3NBMCH', '4NBMCH', '3NCHBM', '4NCHBM'].includes(pkc)
        ).length > 0
      ? false
      : null
    this.ready = true
  },
  watch: {
    'form.country': function () {
      const country = locations.find((lc) => lc.iso2 === this.form.country)
      if (country) {
        this.states = country.states
      }
      if (this.ready) this.form.state = ''
    },
    'form.state': function () {
      if (this.form.state) {
        const stateObj = this.states.find(
          (st) => st.state_code === this.form.state
        )
        if (stateObj) {
          this.cities = stateObj.cities
        }
      }
      if (this.ready) this.form.city = ''
    },
    'form.birthdate': function () {
      if (this.form.birthdate) {
        const years = moment().diff(this.form.birthdate, 'years')
        this.form.babySeat = null
        if (years <= 9) {
          this.showBabySeat = true
        } else {
          this.showBabySeat = false
        }
      }
    },
  },
}
</script>
<style lang="scss">
#Basic {
  .form {
    padding: 8px 16px;
    text-align: left;
    .input-label {
      font-size: 12px;
      margin: 16px 0;
      display: flex;
      .red {
        color: red;
        margin-left: 2px;
      }
    }
  }
  .d-flex {
    display: flex;
    align-items: center;
  }
  .special-form {
    display: flex;
    justify-content: space-between;
  }
  .terms {
    width: auto;
    height: 300px;
    overflow: auto;
    border: 0.5px solid rgb(216, 210, 210);
    border-radius: 5px;
    padding: 0 15px;
  }
  p {
    text-align: justify;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(6, 7, 8);

    animation: spin 1s ease infinite;
  }
  .tooltip {
    margin-left: 8px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0);
    color: white;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
