<template lang="pug">
el-dialog.dialog(
  :title="$t('lang.applyAddressParty.message')"
  :visible="value"
  @update:visible="$emit('input', $event)"
)
  .main-wrapper
    p.dialog-message {{ $t('lang.applyAddressParty.description') }}
    .flex-column
      el-checkbox(v-model="defaultChecked" disabled) {{ partyLeader.name }}
      el-checkbox-group(v-model="selectedIds").flex-column
        el-checkbox(
          v-for="traveller in travellers"
          :label="traveller.id"
        ) {{ traveller.name }}
    .buttons
      el-button#explora-btn.secondary(@click="$emit('input', false)") {{ $t('lang.modal.cancel') }}
      el-button#explora-btn.primary(@click="submit") {{ $t('lang.modal.accept') }}
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    partyLeader: {
      type: Object,
      default: () => ({ id: 0, name: '' }),
    },
    /**
     * Lista de viajeros con id y nombre { id: number, name: string }
     */
    travellers: {
      type: Array,
      default: () => [
        { id: 23423, name: 'viajero 1' },
        { id: 21553, name: 'viajero 2' },
      ],
    },
  },
  data() {
    return {
      selectedIds: [],
      defaultChecked: true,
    }
  },
  methods: {
    submit() {
      this.$emit('input', false)
      this.$emit('submit', this.selectedIds)
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.selectedIds = this.travellers.map((traveller) => traveller.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.dialog-message {
  margin: 0 10px 20px;
  word-break: normal;
}
.buttons {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
  justify-content: end;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.dialog::v-deep .el-dialog {
  width: max-content;
  max-width: 90vw;
  @media (min-width: 720px) {
    max-width: 600px;
  }
}
</style>
