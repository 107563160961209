<template lang="pug">
#PhoneInput
  el-select(v-model="countryCode", placeholder="Prefix" filterable, style="width: 172px; margin-right: 12px;", :filter-method="filterCountry", :disabled="disabled")
    el-option(
      v-for="country in filteredCountryCodes",
      :key="country.name_en",
      :label="`${country.emoji} (+${country.dial_code})`",
      :value="country.dial_code"
    )
      label {{ country.emoji }} ({{ country.dial_code }}) {{ country.name_es }}
  el-input(v-model="internalValue", type="text", :max_length="11", :ref="reference" :id="id" style="width: 65%;" :disabled="disabled")
</template>

<script>
import { countries } from '../../../plugins/phonePrefix.json'

export default {
  props: ['value', 'reference', 'id', 'disabled'],
  data() {
    return {
      internalValue: this.value,
      countryCode: '',
      searchText: '',
    };
  },
  computed: {
    countryCodes() {
      return countries;
    },
    filteredCountryCodes() {
      if (!this.searchText) {
        return this.countryCodes;
      }
      return this.countryCodes.filter(country => {
        return country.name_es.toLowerCase().includes(this.searchText.toLowerCase()) || country.dial_code.includes(this.searchText);
      });
    },
  },
  watch: {
    internalValue(newVal) {
      this.updateValue(newVal);
    },
    countryCode() {
      this.updateValue(this.internalValue);
    },
  },
  methods: {
    updateValue (newVal) {
      let val = ''
      if (this.countryCode) {
        val = this.countryCode + newVal;
      } else {
        val = newVal;
      }
      this.$emit('input', val);
    },
    filterCountry(query) {
      this.searchText = query;
    },
    detectCountry(phoneNumber) {
      if (phoneNumber) {
        for (let i = 1; i <= 4; i++) {
          const prefix = phoneNumber.substring(0, i); 
          const country = this.countryCodes.find(country => country.dial_code === prefix);
          if (country) {
            this.countryCode = country.dial_code;
            this.internalValue = phoneNumber.substring(i);
            break;
          }
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.detectCountry(this.value);
    }
  },
};
</script>

<style lang="scss">
#PhoneInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
  