import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHiking,
  faBiking,
  faFrog,
  faPlane,
  faHotel,
  faRoad,
  faAnchor,
  faGlobeAmericas,
  faMountain,
  faCheck,
  faSwimmer,
  faCamera,
  faPalette,
  faBook,
  faCrow,
  faHorse,
  faPaw,
  faMeteor,
  faIcicles,
  faGlobeEurope
} from '@fortawesome/free-solid-svg-icons'

import {
} from '@fortawesome/free-regular-svg-icons'

import {
  faPagelines
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHiking,
  faBiking,
  faFrog,
  faPlane,
  faHotel,
  faRoad,
  faAnchor,
  faGlobeAmericas,
  faMountain,
  faCheck,
  faSwimmer,
  faCamera,
  faPalette,
  faBook,
  faCrow,
  faHorse,
  faPaw,
  faMeteor,
  faIcicles,
  faGlobeEurope,
  faPagelines
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
