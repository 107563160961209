<template lang="pug">
#PDFReader
  pdf(:src="src", @num-pages="onNumPagesChange", :page="page")
  .controls
    el-button(@click="prevPage")
      .el-icon-arrow-left
    el-button(@click="nextPage")
      .el-icon-arrow-right
  span.page {{ page }} / {{ numPages }}
</template>
<script>
import pdf from 'vue-pdf';

export default {
  props: ['src'],
  data() {
    return {
      numPages: 10,
      page: 1
    }
  },
  components: {
    pdf
  },
  methods: {
    onNumPagesChange(numPages) {
      this.numPages = numPages
    },
    nextPage() {
      if (this.page < this.numPages) {
        this.page += 1
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page -= 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#PDFReader {
  .controls {
    display: flex;
    justify-content: space-evenly;
  }
  .page {
    text-align: center;
    margin-top: 8px;
  }
}
</style>
