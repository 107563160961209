/** Constantes de los pasos con sus valores internos */
export const stepValues = {
  BASIC_INFO: 1,
  TRANSPORT_ARRIVAL: 2,
  TRANSPORT_DEPARTURE: 3,
  REQUIREMENTS: 4,
  FITNESS_CONDITIONS: 5,
  INTERESTS: 6,
  ABOUT_US: 7,
  MAPI_VISIT: 8,
}

/** Lista de pasos en el orden que se muestran en el formulario */
export const stepList = [
  stepValues.BASIC_INFO,
  stepValues.TRANSPORT_ARRIVAL,
  stepValues.TRANSPORT_DEPARTURE,
  stepValues.MAPI_VISIT,
  stepValues.REQUIREMENTS,
  stepValues.FITNESS_CONDITIONS,
  stepValues.INTERESTS,
  stepValues.ABOUT_US,
]
