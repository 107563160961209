<template lang="pug">
#TransportBox
  .bordered-box
    div
      div
        font-awesome-icon.icon-style(:icon="['fas', icon]", size="5x")
      .text
        label {{ text }}
</template>
<script>
export default {
  props: ['text', 'icon']
}
</script>
<style lang="scss">
#TransportBox {
  cursor: pointer;
  margin-bottom: 16px;
  .bordered-box {
    border: 0.5px solid #a59f9f;
    border-radius: 3px;
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .icon-style {
    color: #6e6d6d;
  }
  .text {
    margin-top: 24px;
  }
}
</style>
